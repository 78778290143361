import React from 'react'
import banner from "../../../../../../videos/userguide/integration/integration1.mov";

export default function Calendar() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Calendar</h3>
        <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>The Calendar feature in Workfast helps users stay organized by scheduling tasks, deadlines, and events in one place. With seamless integration, teams can track project timelines, set reminders, and sync with external calendars for better planning.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click Recommended Apps</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Select Google Calendar and Add to your Integration</p>
                </div>
            </div>
        </div>
    </div>
  )
}
