import React from 'react';
import { AlertImgSvg, ChannelImgSvg, ChatImgSvg, DashboardImgSvg, PeopleImgSvg, ProjectImgSvg, ReportImgSvg, TaskImgSvg } from '../svg';

export default function Desktop() {
    const [iconsIndex, setIconIndex] = React.useState(4);

    const icons = [
        { id: 1, name: "Task", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/task-banner.webp" },
        { id: 2, name: "Alert", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/alert-banner.webp" },
        { id: 3, name: "People", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/people-banner.webp" },
        { id: 4, name: "Chat", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/chat-banner.webp" },
        { id: 5, name: "CXO Dashboard", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/dashboard-banner.webp" },
        { id: 6, name: "Report", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/report-banner.webp" },
        { id: 7, name: "Channel", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/chennal-banner.webp" },
        { id: 8, name: "Project", banner: "https://dz1x1c630cl14.cloudfront.net/webassets/images/home/project-banner.webp" },
    ];

    const totalCards = icons.length;

    const updateCarousel = (index) => {
        return [...Array(9)].map((_, i) => (index + i - 4 + totalCards) % totalCards);
    };

    const cardPositions = updateCarousel(iconsIndex);


    return (
        <div className='mt-[40px] lg:mt-[60px] flex flex-col justify-center items-center gap-[32px] relative z-[15] w-11/12  mx-auto overflow-hidden'>
            <div className='text-center'>
                <h4 className='text-[#180833] font-bold text-[28px] xl:text-[40px]'>One App to Run Your Business with AI-Powered Agents  </h4>
                <h4 className='mt-[12px] text-[#000000] text-[16px] xl:text-[20px] font-meidum'>WorkFast.ai is an  AI-powered project management platform   that helps businesses  Automate Workflows, enhance collaboration, and <br className='hidden xl:block'/> drive efficiency.   Our intelligent system simplifies operations, allowing businesses to focus on growth and productivity.  </h4>
            </div>
            <div className='flex gap-[28px] xl:gap-[36px] items-center justify-center w-full'>
                {cardPositions.map((pos, i) => (
                    <div
                        key={i}
                        className={`w-[96px] xl:w-[106px] shrink-0 flex flex-col gap-[12px] items-center justify-center transition-all duration-500 ${i === 0 ? "opacity-50"  : i === 8 ? "opacity-90" : ""} ${i === 4 ? "scale-[1.3] hover:scale-[1.4]" : ` cursor-pointer hover:scale-[1.1]`}`}
                        onClick={() => setIconIndex(pos)}
                    >
                        <div className='rounded-[20px] size-[50px] lg:size-auto'>
                            {icons[pos]?.id === 1 ?
                                <TaskImgSvg size="50"/>
                                : icons[pos]?.id === 2 ?
                                    <AlertImgSvg size="50" />
                                    : icons[pos]?.id === 3 ?
                                        <PeopleImgSvg size="50"/>
                                        : icons[pos]?.id === 4 ?
                                            <ChatImgSvg size="50"/>
                                            : icons[pos]?.id === 5 ?
                                                <DashboardImgSvg size="50"/>
                                                : icons[pos]?.id === 6 ?
                                                    <ReportImgSvg size="50"/>
                                                    : icons[pos]?.id === 7 ?
                                                        <ChannelImgSvg size="50"/>
                                                        : icons[pos]?.id === 8 &&
                                                        <ProjectImgSvg size="50" />
                            }
                        </div>
                        <h6 className='text-[#7B73AE] text-[12px] xl:text-[14px] font-semibold'>{icons[pos]?.name}</h6>
                    </div>
                ))}
            </div>
            <img
                src={icons[iconsIndex]?.banner}
                alt={icons[iconsIndex]?.name}
                className={`w-[80%]`} 
                loading='lazy'
                width={500}
                height={500}
            />
        </div>
    );
}
