import React, { useEffect } from 'react'
import { LogoSvg } from '../../blog/svg'
import { BsInfoCircle } from "react-icons/bs";
import { Helmet } from 'react-helmet';


export default function CampaignSuccessPage() { // Start with 5 seconds

    useEffect(() => {

        const timer = setTimeout(() => {
            window.location.href = "https://calendly.com/workfastai/one-to-one-demo";
        }, 5000); // 5 seconds delay

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className='w-full h-screen flex justify-center items-center px-[20px] sm:px-0'>
            <Helmet>
                {/* <!-- Google Tag Manager --> */}
                <script>
                    {` 
                    (function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
                    }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', 'GTM-5XZNHKDC');
                    `}
                </script>
                {/* <!-- End Google Tag Manager --> */}

                {/* <!-- Google Tag Manager (noscript) --> */}
                <noscript>
                    {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5XZNHKDC" 
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                </noscript>
                {/* <!-- End Google Tag Manager (noscript) --> */}
            </Helmet>
            <div className='w-full sm:w-[550px] p-[20px] rounded-[20px] relative text-center' style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                <div className='z-[1] w-[60px] absolute rounded-full border-[1px] left-1/2 -translate-x-1/2 -top-[30px]'>
                    <LogoSvg size="60" />
                </div>
                <h1 className='text-[18px] sm:text-[24px] text-gray-500 font-semibold mt-[30px]'>Workfast.ai</h1>
                <h2 className='text-[16px] sm:text-[24px] text-[#000] font-bold mt-[10px]'>Payment Successfully Received</h2>
                <h3 className='text-[14px] sm:text-[20px] text-[#000] font-medium mt-[10px]'>Our team will be reaching out to you shortly</h3>
                <div className='mt-[10px] pt-[10px] border-t border-gray-600 w-full flex gap-[5px] items-center justify-center text-[12px] sm:text-[16px] text-gray-600 font-semibold'>
                    <BsInfoCircle />
                    <h4>We are setting up your personalized demo! 🎯</h4>
                </div>
                <h4 className='text-[12px] sm:text-[16px] text-gray-600 font-semibold'> you will be redirected to book your preferred time slot.</h4>
            </div>
        </div>
    )
}
