import React from 'react'
// import axios from 'axios';
// import API from '../../../../../../utils/API';
import { WorkfastLogo } from '../../../../../header/svg';





export default function Desktop() {

    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    // const handleBookClick = async (e) => {
    //     e.preventDefault();
    //     const response = await axios.post(API.BUTTON,
    //         {
    //             "eventName": "BOOK_FOR_DEMO_HEADER"
    //         }
    //     );
    //     if (response.data.statusCode === 200) {
    //         window.location.href = '/book-for-demo';
    //     }
    // };


    return (
        <div className={`flex justify-between items-center h-[80px] px-[30px] w-full mx-auto lg:px-[60px] py-[8px] z-[55] fixed bg-[#FFF]/[50%]`}>
            <a title='Workfast' href='/'>
                <div className='px-[8px] lg:px-[12px] py-[12px] lg:py-[8px] border border-[#CECEEA] rounded-[16px] relative z-[20] bg-white' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }}>
                    <WorkfastLogo width={position >= 1024 ? "156" : "100"} height={position >= 1024 ? "33" : "25"}/>
                </div>
            </a>
            {/* <div className='relative z-[20]'>
                <button className='py-[8px] lg:py-[10px] px-[14px] text-[#000] text-[13px] lg:text-[16px] font-semibold rounded-[10px] border border-[#C5A313] bg-[#FDD01F]' onClick={(e) => { handleBookClick(e) }}>Apply For Franchise</button>
            </div> */}
        </div>
    )
}
