import React from 'react'
import banner from "../../../../../../videos/userguide/docs/docs1.mov";

export default function DocsContent() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Docs</h3>
        <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Workfast Docs lets teams create, store, and collaborate on documents with real-time editing, version control, and smart content suggestions. Easily add comments, link docs to projects or tasks, and customize fonts in settings.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Add Comments</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Map with Project or Tasks</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>3</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click Settings to change fonts</p>
                </div>
            </div>
        </div>
    </div>
  )
}
