import React from 'react'
import akila_mam from "../../../../../components/images/campaign_deals/akila_mam.png";
import paramaiha from "../../../../../components/images/campaign_deals/paramaiha.png";
import srinivasan from "../../../../../components/images/campaign_deals/srinivasan.png";
import Play from "../../../../../components/images/campaign_deals/Play.svg";


export default function Feedback({setVideoId}) {

    const video_thumbanil = [
        { image: `${akila_mam}`, videoId: "zf6qvA3dQLE" },
        { image: `${paramaiha}`, videoId: "05R3MMyHeC8" },
        { image: `${srinivasan}`, videoId: "ntlq0wPxk7g" },
    ];

    
    return (
        <div className='mt-[20px] sm:mt-[60px] mb-[20px] px-[20px] sm:px-0 w-full bg-[#F8FEFE] relative z-[15]'>
            <div className='text-center'>
                <h3 className='text-[#000] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[45px] font-semibold'>What Our Customers Says</h3>
                <h4 className='text-[#383838] text-[18px] lg:text-[20px] xl:text-[22px] font-normal mt-[18px] hidden sm:flex justify-center w-full'>Harmony of Happiness: Cherished Tales from <br /> Our Valued Customers</h4>
                <h4 className='text-[#383838] text-[14px] font-normal mt-[18px] sm:hidden text-center'>Harmony of Happiness: Cherished Tales from Our Valued Customers</h4>
            </div>
            <div className='mt-[32px] flex flex-wrap gap-[30px] items-center justify-center'>
                {video_thumbanil.map((item, index) => (
                    <div key={index} className='sm:p-[20px] focus:outline-none relative'>
                        <div className='relative cursor-pointer focus:outline-none'>
                            <img src={Play} alt='play' className='absolute size-[40px] sm:size-[50px] sm:h-auto top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2' onClick={() => setVideoId(`${item.videoId}`)} />
                            <img src={`${item.image}`} alt='profile' className='rounded-[8px] object-cover w-[450px]' />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
