import React from 'react'
import { Link } from 'react-scroll';
import CustomizingYourReport from './customizing-your-report';
import CreateReport from './create-a-report';
import CustomizableAxis from './customizable-axis';
import TableView from './table-view';
import GraphView from './graph-view';
import SavingViewingReports from './saving-viewing-reports';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Customizing Your Report', id: 'customizing-your-report' },
        { title: 'Create a Report', id: 'create-a-report' },
        { title: 'Customizable Axis', id: 'customizable-axis' },
        { title: 'Table View', id: 'table-view' },
        { title: 'Graph View', id: 'graph-view' },
        { title: 'Saving and Viewing Reports', id: 'saving-viewing-reports' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass=""
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                offset={-100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>Reports in Workfast help you track project and task performance, providing detailed insights and data summaries. You can generate reports to analyze productivity, task completion, and team progress. These reports can be customized to display key metrics, visual graphs, and timelines, making it easier to monitor workflow and make informed decisions. You can export these reports in various formats, helping you share updates with stakeholders or for internal analysis.</p>
                </div>
                <div id='customizing-your-report'><CustomizingYourReport /></div>
                <div id='create-a-report'><CreateReport/></div>
                <div id='customizable-axis'><CustomizableAxis/></div>
                <div id='table-view'><TableView/></div>
                <div id='graph-view'><GraphView/></div>
                <div id='saving-viewing-reports'><SavingViewingReports/></div>
            </div>
        </div>
    )
}
