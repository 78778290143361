import React from 'react'

export default function Banner() {

  return (
    <div className='sm:last:mt-[40px] lg:mt-[65px] flex flex-col gap-[20px] sm:gap-[40px] lg:gap-[60px] xl:gap-[90px] justify-center items-center mx-auto relative overflow-hidden z-[15] px-[20px] sm:px-0'>
        <div className='flex flex-col items-center justify-center text-center gap-[16px] lg:gap-[24px] my-[30px] sm:my-[100px] relative z-[55]'>
            <h1 className='text-[#0A0800] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[64px] font-bold'>Welcome to WorkFast.ai <br className='hidden sm:block'/> First SaaS Franchise Program</h1>
            <h2 className='text-[#3B394E] text-[14px] sm:text-[18px] lg:text-[24px] font-medium'>Congratulations on taking the next step towards owning a WorkFast.ai <br className='hidden sm:block xl:hidden'/> SaaS Franchise. This document <br className='hidden xl:block'/> provides detailed insights into <br className='hidden sm:block xl:hidden'/> the franchise model, investment structure, revenue potential, <br className='hidden sm:block xl:hidden'/> responsibilities, and <br className='hidden xl:block'/> support provided to franchise partners</h2>
            {/* <p className='text-[#3B394E] text-[18px] lg:text-[24px] font-semibold'>Get Started Today</p> */}
        </div>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/website-background.png" alt='background' className='absolute z-[0] w-full top-0' loading='lazy'/>
    </div>
  )
}
