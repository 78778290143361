import React from 'react';

export default function Pincode() {
  return (
    <div className='bg-[#EFF0FF] px-[20px] sm:px-[28px] py-[20px] lg:pt-[40px] grid lg:grid-cols-2 lg:gap-[30px] justify-center mt-[30px] sm:mt-[60px]'>
        <div className='flex justify-center items-center'>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/pincode.webp" alt='banner' loading='lazy' className='mx-auto w-full object-cover' />
        </div>
        <div className='flex flex-col justify-start items-start gap-[14px] xl:gap-[28px] 2xl:mt-[50px]' id='position'>
            <h3 className='text-[28px] sm:text-[40px] xl:text-[50px] text-[#272727] font-bold'>What is WorkFast.ai SaaS  Franchise?</h3>
            <p className='text-[14px] xl:text-[22px] text-[#484A60] font-normal mt-[2px]'>Franchises have traditionally been associated with Edu-tech, fast-food chains and lifestyle brands, etc.. Now, for the first time, we are launching a franchise model for Software as a Service (SaaS).</p>
            <p className='text-[14px] xl:text-[22px] text-[#484A60] font-normal'>We believe in the democratization of wealth—creating opportunities where <br className='hidden 2xl:block'/> entrepreneurs and business professionals can grow alongside a <br className='hidden 2xl:block'/> cutting-edge software business. <br className='sm:hidden'/> <span className='text-[#000227] font-semibold relative'>You Grow, We Grow. <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/line.png" alt='line' className='absolute right-0' /></span></p>
            <p className='text-[14px] xl:text-[22px] text-[#484A60] font-normal'>By owning a WorkFast.ai SaaS Franchise, you help bring AI-driven <br className='hidden 2xl:block'/> solutions to businesses while earning recurring revenue.</p>
        </div>
    </div>
  )
}
