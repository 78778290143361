import React from 'react'
import { BuildSpanSvg } from '../../../confidential/component/svg';

export default function LevelsRewards() {
    const [position, setPosition] = React.useState(window.innerWidth);
    // const [startValue, setStartValue] = React.useState(null);
    // const headerRef = React.useRef(null);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    // React.useEffect(() => {
    //     if (headerRef.current) {
    //         const startValue = headerRef.current.getBoundingClientRect().left;
    //         setStartValue(startValue)
    //     }
    // }, []);

    return (
        <div className='mt-[20px] sm:mt-[60px] relative text-center'>
            <div className='relative max-w-[700px] lg:max-w-[950px] mx-auto px-[20px] sm:px-0'>
                <h3 className='text-[#181F38] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Location-Specific Franchise: Levels & Rewards</h3>
                <div className={`absolute top-[3px] lg:top-1/2 lg:-translate-y-1/2 left-[180px] sm:left-[330px] z-0`}>
                    <BuildSpanSvg colour="#AF52DE" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <div className='pl-[20px] sm:px-[40px] mt-[20px] sm:mt-[36px]'>
                <p className='text-[#2B2B2B] text-[20px] sm:text-[29px] font-semibold text-center'>Metro City Franchise ROI</p>
                <div className='overflow-x-scroll'>
                    <div className='w-[400px] sm:w-11/12 lg:w-8/12 mx-auto text-[#000000] text-[13px] lg:text-[17px] font-semibold mt-[20px]'>
                        <div className='grid grid-cols-3 border border-[#A0A0A0] w-full rounded-t-[22px]'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#FF3B3033] border-r border-[#A0A0A0] rounded-tl-[22px] text-[#FF3B30] font-bold'>
                                <p>Franchise Level</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#FF950033] border-r border-[#A0A0A0]  text-[#FF9500] font-bold'>
                                <p>Criteria</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#FFCC0033] border-[#A0A0A0] rounded-tr-[22px] text-[#C2A700] font-bold'>
                                <p>Rewards & Benefits</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 border-[#A0A0A0] border-b border-x w-full'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                                <p>Gold</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>100+ Companies Onboarded</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>Priority Support, Lead Sharing, Exclusive Discounts</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 border-[#A0A0A0] border-b border-x w-full'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                                <p>Platinum</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>250+ Companies Onboarded</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>Priority Support, Lead Sharing, Exclusive Discounts</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 border-[#A0A0A0] border-b border-x w-full'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                                <p>Diamond</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>500+ Companies Onboarded</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>Direct WorkFast.ai Partnership, Expansion <br className='hidden xl:block' /> Support, Special Incentives</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 border-[#A0A0A0] border-b border-x w-full'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                                <p>Titanium</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>1,000+ Companies Onboarded</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>Top-Tier Revenue Share, Exclusive Expansion <br className='hidden xl:block' /> Opportunities, Global Brand Ambassador <br className='hidden xl:block' /> Privileges</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                                <p>Million Dollar Club</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>Country-Level Master Franchise</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>Exclusive Country Rights, Revenue from <br className='hidden xl:block' /> Location-Level Franchise Sales, Premium <br className='hidden xl:block' /> Global Networking, and Strategic <br className='hidden xl:block' /> Growth Support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
