import React from 'react'
import ConnectYourAccount from './connect-your-account';
import Calendar from './calendar';
import Events from './events';
import CreateEvent from './create-a-event';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>Connect Workfast.ai with your favorite tools to streamline workflows. Integrate third-party apps effortlessly and enhance productivity.</p>
            </div>
            <div id='connect-your-account'><ConnectYourAccount /></div>
            <div id='calendar'><Calendar /></div>
            <div id='events'><Events /></div>
            <div id='create-a-event'><CreateEvent /></div>
        </div>
    )
}
