import React from 'react'
import banner from "../../../../../../videos/userguide/integration/integration2.mov";

export default function ConnectYourAccount() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Connect your account</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
      <div className='mt-[6px]'>
        <p className='text-[#000000] text-[14px] font-medium'>TThe Connect Your Account feature in Workfast allows users to link their profiles with third-party services for a seamless workflow. By integrating cloud storage, communication tools, and project management apps, users can access all their data in one place.</p>
        <div className='mt-[11px] flex flex-col gap-[7px]'>
          <div className='flex gap-[4px] items-start'>
            <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
            <p className='text-[#000000] text-[14px] font-normal'>Click Connect Account & Integrate with Calendar</p>
          </div>
        </div>
      </div>
    </div>
  )
}
