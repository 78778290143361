import React from 'react'
import banner from "../../../../../../videos/userguide/integration/integration2.mov";

export default function ConnectYourAccount() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Connect your account</h3>
        <video src={banner} autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>The Connect Your Account feature in Workfast allows users to link their profiles with third-party services for a seamless workflow. By integrating cloud storage, communication tools, and project management apps, users can access all their data in one place.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Connect Account & Integrate with Calendar</p>
                </div>
            </div>
        </div>
    </div>
  )
}