import React from 'react'
import banner from "../../../../../../images/userguide/kanban-view.webp"

export default function KanbanView() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Kanban View</h3>
        <img src={banner} alt='invite' className='rounded-[12px]' loading='lazy'/>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Set up a dedicated area for your project by creating a new “Project Space.” Go to the “Create Space” option, name your space, and customize it with relevant settings and permissions. This feature helps you keep all project-related tasks, files, and discussions organized in one place.</p>
        </div>
    </div>
  )
}
