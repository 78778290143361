import React from 'react'
import banner from "../../../../../../videos/userguide/integration/integration3.mov";

export default function Events() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
      <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Events</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[10px]'>
        <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Events lets teams plan, manage, and track meetings, deadlines, and milestones with reminders and calendar integration. Create events, accept invites, and view past events to stay organized and never miss an update!</p>
        <div className='mt-[22px] flex flex-col gap-[15px]'>
          <div className='flex gap-[8px] items-start'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>1</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Create Event to start a event</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>2</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Accept the Invite</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>3</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>See the Past Events</p>
          </div>
        </div>
      </div>
    </div>
  )
}
