import React from 'react'
import banner from "../../../../../../videos/userguide/docs/docs5.mov";

export default function CreateTable() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Create a Table</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
      <div className='mt-[6px]'>
        <p className='text-[#000000] text-[14px] font-medium'>The Create a Table feature in Workfast allows users to organize information clearly and efficiently. With customizable rows and columns, tables help structure data for better readability. Users can easily format, merge cells, and add headers to enhance clarity. Whether for project tracking, reports, or comparisons, tables make documentation more structured and insightful.</p>
        <div className='mt-[11px] flex flex-col gap-[7px]'>
          <div className='flex gap-[4px] items-start'>
            <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
            <p className='text-[#000000] text-[14px] font-normal'>Create Table, Customise your fonts, Add multiple headings</p>
          </div>
        </div>
      </div>
    </div>
  )
}
