import React from 'react'
import DocsContent from './docs-content';
import Comments from './comments';
import NewDocument from './new-document';
import Export from './export1';
import CreateTable from './create-a-table';
import AddMedia from './add-media';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>Workfast Docs enables teams to create, store, and collaborate on documents seamlessly. From project plans to meeting notes, easily create, edit, and manage files within Workfast.ai with seamless sharing and exporting.</p>
            </div>
            <div id='docs'><DocsContent /></div>
            <div id='comments'><Comments /></div>
            <div id='new-document'><NewDocument /></div>
            <div id='export'><Export /></div>
            <div id='create-a-table'><CreateTable /></div>
            <div id='add-media'><AddMedia /></div>
        </div>
    )
}
