import React from 'react'
import banner from "../../../../../../videos/userguide/docs/docs3.mov";
import { FaPlus } from 'react-icons/fa6';


export default function NewDocument() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>New Document</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
      <div className='mt-[6px]'>
        {/* <p className='text-[#000000] text-[14px] font-medium'>Workfast Docs simplifies document creation with real-time collaboration and AI-powered assistance. Teams can effortlessly draft, edit, and share content while ensuring version control and seamless integration within projects. With smart formatting and auto-save features, users can focus on productivity without worrying about losing progress. Whether for meeting notes, reports, or brainstorming, Workfast keeps everything organized and accessible. 🚀</p> */}
        <div className='mt-[11px] flex flex-col gap-[7px]'>
          <div className='flex gap-[4px] items-start'>
            <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
            <div className='text-[#000000] text-[14px] font-normal flex gap-[4px] items-center flex-wrap'><p>Create a New Document by clicking the</p> <div className='bg-[#FFDD09] size-[14px] flex justify-center items-center rounded-full'><FaPlus className='text-[8px] text-[#473D00]' /></div> <p>Icon as Table, List or a Page</p></div>
          </div>
        </div>
      </div>
    </div>
  )
}
