import React from 'react'
import { LuPlus, LuMinus } from "react-icons/lu";

export default function Faq() {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isOpenIndex, setIsOpenIndex] = React.useState(0);

    const faq = [
        { question: 'What is the earning potential as a WorkFast.ai Franchisee?', answer: "Your earnings depend on the number of licenses sold and renewed in your Location. Franchisees can generate steady, recurring monthly revenue, with Metro city franchisees earning significantly higher due to business density." },
        { question: 'Do I need technical expertise?', answer: "No! WorkFast.ai provides comprehensive training and ongoing support to help you succeed in sales and implementation." },
        { question: 'Can I expand to multiple Locations?', answer: "Yes! Franchisees who perform well can expand to additional Location territories for increased revenue and market dominance." },
        { question: 'What happens after one year?', answer: "Franchisees must renew their annual license to continue selling in their assigned Location. Renewals are based on performance and sales metrics." },
        { question: 'What kind of support does WorkFast.ai provide?', answer: "We provide sales & technical training, marketing materials, lead generation support, and 24/7 technical assistance to help you succeed." },
        { question: 'How long does it take to start earning revenue?', answer: "It depends on your sales efforts, but many franchisees start closing deals within the first 30-60 days after onboarding and training." },
        { question: 'Can I sell additional services along with WorkFast.ai?', answer: "Yes! You can bundle WorkFast.ai licenses with consulting, implementation, and training services to maximize your revenue potential." },
        { question: 'Are there any additional costs apart from the franchise fee?', answer: "Aside from the annual franchise fee, you may need to invest in local marketing, lead generation, and networking to drive sales in your Location." },
        { question: 'Is this a passive income opportunity?', answer: "No, this is a business opportunity, not a passive investment. Success depends on your sales efforts, networking, and ability to close deals." },
        { question: 'What makes WorkFast.ai different from other SaaS businesses?', answer: "Unlike standard SaaS products, WorkFast.ai offers a franchise-based revenue model, allowing business owners to sell licenses, earn renewals, and expand within their territory." },
    ]
    
    return (
        <div className='mt-[60px] relative px-[20px] sm:px-0 flex flex-col items-center'>
            <h4 className='text-[28px] sm:text-[40px] xl:text-[60px] text-[#0F0200] font-bold text-center'>Frequently Asked Questions (FAQ)</h4>
            <h5 className='text-[#000000] text-[16px] sm:text-[24px] xl:text-[34px] font-medium mt-[20px] text-center'>Have Questions? Get Answers Now!</h5>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px]' style={{boxShadow: "5px 5px 100px 0px #FFDD0933"}}>Contact Us for More Info</button> */}
            <div className='mt-[20px] sm:mt-[40px] lg:mt-[60px] mx-auto sm:w-8/12 xl:w-1/2'>
                {faq.map((each, index) => (
                    <div key={index} className={`px-[26px] py-[21px] border-[#303030] flex-col gap-[8px] sm:gap-[12px] rounded-[18px] mt-[20px] ${isOpen && index === isOpenIndex ? "bg-[#F8F8F8]" :"bg-[#FFF]"}`} style={{ boxShadow : index !== isOpenIndex && "0px 1px 4px 0px #8F8F8F33"}}>
                        <div className='flex justify-between items-center gap-[10px]'>
                            <h2 className='text-[16px] lg:text-[20px] text-[#272727] font-semibold'>{each.question}</h2>
                            {isOpen && index === isOpenIndex ? (
                                <LuMinus className={`text-[#272727] text-[16px] lg:text-[20px] cursor-pointer transition-opacity duration-500 flex shrink-0 ${isOpen && index === isOpenIndex ? "opacity-100" : "opacity-0"}`} onClick={() => { setIsOpen(false) }} />
                            ) : (
                                <LuPlus className={`text-[#8F8F8F] text-[16px] lg:text-[20px] cursor-pointer transition-opacity duration-500 flex shrink-0 ${isOpen && index === isOpenIndex ? "opacity-0" : "opacity-100"}`} onClick={() => { setIsOpen(true); setIsOpenIndex(index) }} />
                            )}
                        </div>
                        <div className={`overflow-hidden transition-all duration-500 ${isOpen && index === isOpenIndex ? 'max-h-screen opacity-100 mt-[7px]' : 'max-h-0 opacity-0'}`}>
                            <p className='text-[12px] lg:text-[18px] text-[#5C5C5C] font-normal'>{each?.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
