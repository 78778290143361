import React from 'react'
import Header from './header'
import Footer from './footer'
import Banner from './banner'
import InvestmentModel from './investment_model'
import PincodeRights from './pincode_rights'
import ROI from './roi'
import Roadmap from './roadmap'
import LevelsRewards from './levels_rewards'
import Models from './models'
import PartnerDriven from './partner_driven'
import Structure from './structure'
import Faq from './faq'
import Traning from './training'
import Available from './availables'


export default function ConfidentialFr() {

    return (
        <div className='relative overflow-hidden'>
            <Header />
            <Banner />
            <InvestmentModel />
            <PincodeRights />
            <ROI />
            <Roadmap/>
            <LevelsRewards/>
            <Models/>
            <PartnerDriven />
            <Structure/>
            <Faq />
            <Traning />
            <Available />
            <Footer />
        </div>
    )
}