import React from 'react'
// import { BuildSpanSvg } from '../../../confidential/component/svg';

export default function ROI() {
    // const [position, setPosition] = React.useState(window.innerWidth);
    // const [startValue, setStartValue] = React.useState(null);
    // const headerRef = React.useRef(null);

    // React.useEffect(() => {
    //     window.onresize = function () {
    //         setPosition(window.innerWidth);
    //     };
    // }, []);

    // React.useEffect(() => {
    //     if (headerRef.current) {
    //         const startValue = headerRef.current.getBoundingClientRect().left;
    //         setStartValue(startValue)
    //     }
    // }, []);
    return (
        <div className='mt-[20px] sm:mt-[60px] relative text-center'>
            <div>
                <h4 className='text-[#2B2B2B] text-[24px] sm:text-[34px] font-bold px-[20px] sm:px-0'>ROI & Revenue Potential (3-Year Projection)</h4>
                {/* <h5 className='text-[#000000] text-[20px] sm:text-[28px] font-meidum mt-[7px] sm:mt-[14px] px-[20px] sm:px-0'>Start Earning Recurring Revenue Now!</h5>
                <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[37px]' style={{ boxShadow: "5px 5px 100px 0px #FFDD0933" }}>Calculate Your ROI & Apply</button> */}
            </div>
            <div className='px-[20px] sm:px-[40px] mt-[20px] sm:mt-[36px]'>
                <p className='text-center text-[#2B2B2B] text-[20px] sm:text-[29px] font-bold'>Metro City Franchise ROI</p>
                <div className='overflow-x-scroll'>
                    <div className='w-[950px] text-[#000000] text-[13px] sm:text-[17px] font-semibold mt-[20px] overflow-x-scroll mx-auto'>
                        <div className='grid grid-cols-6 border border-[#A0A0A0] w-full rounded-t-[22px]'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FF3B3033] border-r border-[#A0A0A0] rounded-tl-[22px] text-[#FF3B30] font-bold'>
                                <p>Projection</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FF950033] border-r border-[#A0A0A0]  text-[#FF9500] font-bold'>
                                <p>Companies Onboarded <br /> Per Month</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FFCC0033] border-r border-[#A0A0A0]  text-[#C2A700] font-bold'>
                                <p>Licenses Sold Per <br /> Month</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#34C75933] border-r border-[#A0A0A0]  text-[#0FB338] font-bold'>
                                <p>Year 1 Revenue <br /> (INR)</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#00C7BE33] border-r border-[#A0A0A0]  text-[#00C3BA] font-bold'>
                                <p>Year 2 Revenue <br /> (INR)</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#30B0C74D] rounded-tr-[22px] text-[#1EA7C0] font-bold'>
                                <p>Year 3 Revenue <br /> (INR)</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                                <p>Low</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>50</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>1,250</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹30,00,000</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹60,00,000</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹90,00,000</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                                <p>Medium</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>75</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>1,875</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹45,00,000</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹90,00,000</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹1,35,00,000</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                                <p>High</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>100</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>2,500</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹60,00,000</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹1,20,00,000</p>
                            </div>
                            <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                                <p>₹1,80,00,000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-[20px] sm:px-[40px] mt-[20px] sm:mt-[36px]'>
                <p className='text-center text-[#2B2B2B] text-[20px] sm:text-[29px] font-bold'>Tier 2 City Franchise ROI</p>
                <div className='overflow-x-scroll'>
                <div className='w-[950px] overflow-x-scroll text-[#000000] text-[13px] sm:text-[17px] font-semibold mt-[20px] mx-auto'>
                    <div className='grid grid-cols-6 border border-[#A0A0A0] w-full rounded-t-[22px]'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FF3B3033] border-r border-[#A0A0A0] rounded-tl-[22px] text-[#FF3B30] font-bold'>
                            <p>Projection</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FF950033] border-r border-[#A0A0A0]  text-[#FF9500] font-bold'>
                            <p>Companies Onboarded <br /> Per Month</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FFCC0033] border-r border-[#A0A0A0]  text-[#C2A700] font-bold'>
                            <p>Licenses Sold Per <br /> Month</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#34C75933] border-r border-[#A0A0A0]  text-[#0FB338] font-bold'>
                            <p>Year 1 Revenue <br /> (INR)</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#00C7BE33] border-r border-[#A0A0A0]  text-[#00C3BA] font-bold'>
                            <p>Year 2 Revenue <br /> (INR)</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#30B0C74D] rounded-tr-[22px] text-[#1EA7C0] font-bold'>
                            <p>Year 3 Revenue <br /> (INR)</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                            <p>Low</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>30</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>750</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹18,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹36,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹54,00,000</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                            <p>Medium</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>50</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>1,250</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹30,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹60,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹90,00,000</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                            <p>High</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>70</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>1,750</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹42,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹84,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹1,26,00,000</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='px-[20px] sm:px-[40px] mt-[20px] sm:mt-[36px]'>
                <p className='text-center text-[#2B2B2B] text-[20px] sm:text-[29px] font-bold'>Tier 3 City Franchise ROI</p>
                <div className='overflow-x-scroll'>
                    <div className='w-[950px] text-[#000000] text-[13px] sm:text-[17px] font-semibold mt-[20px] mx-auto overflow-x-scroll'>
                    <div className='grid grid-cols-6 border border-[#A0A0A0] w-full rounded-t-[22px]'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FF3B3033] border-r border-[#A0A0A0] rounded-tl-[22px] text-[#FF3B30] font-bold'>
                            <p>Projection</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FF950033] border-r border-[#A0A0A0]  text-[#FF9500] font-bold'>
                            <p>Companies Onboarded <br /> Per Month</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FFCC0033] border-r border-[#A0A0A0]  text-[#C2A700] font-bold'>
                            <p>Licenses Sold Per <br /> Month</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#34C75933] border-r border-[#A0A0A0]  text-[#0FB338] font-bold'>
                            <p>Year 1 Revenue <br /> (INR)</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#00C7BE33] border-r border-[#A0A0A0]  text-[#00C3BA] font-bold'>
                            <p>Year 2 Revenue <br /> (INR)</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#30B0C74D] rounded-tr-[22px] text-[#1EA7C0] font-bold'>
                            <p>Year 3 Revenue <br /> (INR)</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                            <p>Low</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>20</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>500</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹18,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹36,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹54,00,000</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                            <p>Medium</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>40</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>1,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹24,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹48,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹72,00,000</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-6 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                            <p>High</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>60</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>1,500</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹36,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹72,00,000</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>₹1,08,00,000</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* <div className='mt-[20px] sm:mt-[40px]'>
                <div className='relative max-w-[330px] sm:max-w-[432px] mx-auto'>
                    <h3 ref={headerRef} className='text-[#181F38] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Revenue Assumption</h3>
                    <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[${startValue}px] sm:-left-[30px] z-0`}>
                        <BuildSpanSvg colour="#30B0C7" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                    </div>
                </div>
                <p className='mt-[12px] text-[18px] sm:text-[24px] font-medium text-[#000000] px-[20px] sm:px-0'>Each company purchases 25 licenses, and two sales representatives close one company per day each. <br className='hidden sm:block'/> Annual Franchise Fees needs to be renewed every year and it will be given only upon great performance.</p>
            </div> */}
            <p className='text-[#000000] text-[14px] sm:text-[20px] font-medium mt-[20px] px-[20px] sm:px-0'><span className='text-[#FF3B30]'>* T&C :</span> Annual Franchise Fees needs to be renewed every year and it will be given only upon great performance.</p>
        </div>
    )
}
