import React from 'react'
import { Link } from 'react-scroll';
import CreateNewChannel from './create-new-channel';
import CustomizeChanelIcon from './customize-channel-icon';
import SetChannelVisibility from './set-channel-visibility';
import PublicChannel from './public-channel';
import PrivateChannel from './private-channel';
import AddColleagues from './add-colleagues';
import StartConversations from './start-conversations';
import TagColleagues from './tag-colleagues';
import ReactMessages from './react-to-messages';
import ReplyMessages from './reply-to-messages';
import ThreadedReplies from './threaded-replies';
import ReplyChannel from './reply-to-channel';
import ShareAttachments from './share-attachments';
import SendAudioMessages from './send-audio-messages';
import MessageActions from './message-actions';
import ChannelDetails from './channel-details';
import ViewPinnedSavedMessages from './view-pinned-saved-messages';
import ManageChannelMembers from './manage-channel-members';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Create a New Channel', id: 'create-new-channel' },
        { title: 'Customize Channel Icon', id: 'customize-channel-icon' },
        { title: 'Set Channel Visibility', id: 'set-channel-visibility' },
        { title: 'Public Channel', id: 'public-channel' },
        { title: 'Private Channel', id: 'private-channel' },
        { title: 'Add Colleagues', id: 'add-colleagues' },
        { title: 'Start Conversations', id: 'start-conversations' },
        { title: 'Tag Colleagues', id: 'tag-colleagues' },
        { title: 'React to Messages', id: 'react-to-messages' },
        { title: 'Reply to Messages', id: 'reply-to-messages' },
        { title: 'Threaded Replies', id: 'threaded-replies' },
        { title: 'Reply to Channel', id: 'reply-to-channel' },
        { title: 'Share Attachments', id: 'share-attachments' },
        { title: 'Send Audio Messages', id: 'send-audio-messages' },
        { title: 'Message Actions', id: 'message-actions' },
        { title: 'Channel Details', id: 'channel-details' },
        { title: 'View Pinned and Saved Messages', id: 'view-pinned-saved-messages' },
        { title: 'Manage Channel Members', id: 'manage-channel-members' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                offset={-100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>In Workfast.ai, channels are dedicated spaces within your workspace where teams can communicate, collaborate, and stay organized around specific topics or projects. Each channel can be customized to allow members, guests, or customers to participate and share relevant updates. You can create channels for different purposes such as team discussions, project collaborations, or general announcements.</p>
                </div>
                <div id='create-new-channel'><CreateNewChannel /></div>
                <div id='customize-channel-icon'><CustomizeChanelIcon/></div>
                <div id='set-channel-visibility'><SetChannelVisibility/></div>
                <div id='public-channel'><PublicChannel/></div>
                <div id='private-channel'><PrivateChannel/></div>
                <div id='add-colleagues'><AddColleagues/></div>
                <div id='start-conversations'><StartConversations /></div>
                <div id='tag-colleagues'><TagColleagues/></div>
                <div id='react-to-messages'><ReactMessages/></div>
                <div id='reply-to-messages'><ReplyMessages/></div>
                <div id='threaded-replies'><ThreadedReplies/></div>
                <div id='reply-to-channel'><ReplyChannel/></div>
                <div id='share-attachments'><ShareAttachments /></div>
                <div id='send-audio-messages'><SendAudioMessages/></div>
                <div id='message-actions'><MessageActions/></div>
                <div id='channel-details'><ChannelDetails/></div>
                <div id='view-pinned-saved-messages'><ViewPinnedSavedMessages/></div>
                <div id='manage-channel-members'><ManageChannelMembers/></div>
            </div>
        </div>
    )
}
