import React from 'react'

export default function InvestmentModel() {
    return (
        <div className='mt-[20px] sm:mt-[60px] text-center px-[20px] xl:px-0'>
            {/* <h4 className='text-[#000000] text-[22px] sm:text-[26px] lg:text-[32px] font-semibold'>As a WorkFast.ai franchisee, you will gain access to a thriving AI-powered SaaS <br className='hidden xl:block'/> business with long-term recurring revenue potential.</h4> */}
            <h4 className=' text-[#2B2B2B] text-[24px] sm:text-[34px] lg:text-[54px] font-bold'>Franchise Investment Model</h4>
            <h5 className='mt-[11px] text-[#000000] text-[18px] sm:text-[26px] lg:text-[32px] font-semibold'>Location-Specific Franchise Investment</h5>
            <div className='w-full sm:w-11/12 lg:w-8/12 mx-auto mt-[20px] sm:mt-[40px]  text-[#000000] text-[13px] sm:text-[17px] font-semibold'>
                <div className='grid grid-cols-2 border-[#C5AB87] border rounded-t-[22px] w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FFE1B7] border-r border-[#C5AB87] rounded-tl-[22px] text-[#ED8B00] font-bold'>
                        <p>City Category</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#FFF6BD] rounded-tr-[22px] text-[#A58E00] font-bold'>
                        <p>Upfront Franchise Cost (INR)</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border-b border-x w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>Metro Cities (Tier 1)</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>₹10,00,000</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border-b border-x w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>Tier 2 Cities</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>₹5,00,000</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border-b border-x w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>Tier 3 Cities</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>₹3,00,000</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border-b border-x w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>Medium Business Demand Areas</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>₹25,00,000</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>High Business Demand Areas (IT hubs, startup zones, <br className='hidden lg:block'/> corporate clusters, major industrial areas) </p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>₹50,00,000</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
