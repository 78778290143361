import React from 'react'
import banner from "../../../../../../videos/userguide/docs/docs6.mov";

export default function AddMedia() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Add Media</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
      <div className='mt-[6px]'>
        <p className='text-[#000000] text-[14px] font-medium'>The Add Media feature in Workfast enables users to enhance documents with images, videos, and attachments. Users can easily upload files, embed links, or drag and drop media for better visualization. This helps in creating engaging and informative content while maintaining a structured workflow. Whether for presentations, reports, or creative projects, media integration makes documents more dynamic and effective.</p>
        <div className='mt-[11px] flex flex-col gap-[7px]'>
          <div className='flex gap-[4px] items-start'>
            <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
            <p className='text-[#000000] text-[14px] font-normal'>Add Image, Video, Audio or PDF</p>
          </div>
        </div>
      </div>
    </div>
  )
}
