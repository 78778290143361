import React from 'react'
import { LogoSvg } from '../../../blog/svg'

export default function Future() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='mt-[30px] sm:mt-[60px] relative bg-[#FFFDF4] px-[20px] sm:px-0 py-[21px] flex items-center flex-col'>
            <div className='relative z-[1]'>
                <LogoSvg size={position <= 645 ? "56" : "76"} />
            </div>
            <p className='mt-[13px] text-[22px] sm:text-[40px] text-[#000000] font-semibold'>Workfast.ai</p>
            <p className='mt-[22px] text-[24px] sm:text-[42px] text-[#000000] font-bold text-center'>The Future of Work is Here!</p>
            <p className='text-[#1D272C] text-[12px] sm:text-[18px] font-normal mt-[32px] text-center'>Your Investment    •    Your Business    •    Your Success</p>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[28px]' style={{ boxShadow: "5px 5px 100px 0px #FFDD0933" }}>Apply Now</button> */}
        </div>
    )
}
