import React from 'react'

export default function Banner() {


  return (
    <div className='sm:mt-[40px]  flex flex-col gap-[40px] lg:gap-[60px] xl:gap-[90px] justify-center items-center mx-auto relative overflow-hidden z-[15]'>
      <div className='flex flex-col gap-[20px] sm:gap-[10px] justify-center items-center w-11/12 mx-auto my-[40px] sm:mt-[100px] sm:mb-[60px]' id="banner">
        <div className='flex flex-col text-start gap-[16px] lg:gap-[24px] relative z-[55]'>
          <h1 className='text-[#180833] text-[32px] lg:text-[40px] xl:text-[64px] font-bold text-center'>WorkFast.ai First SaaS Franchise 🚀 </h1>
          {/* <h2 className='text-[#FF3B30] text-[18px] lg:text-[36px] font-semibold text-center'>Own Your Territory.</h2> */}
        </div>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/banner.png" alt='banner' className='relative z-[55] w-[80%] sm:w-[50%] rounded-[12px]' loading='lazy' />
        <div className='relative z-[20] w-10/12 mx-auto flex justify-center'>
          <button className='py-[8px] lg:py-[10px] px-[14px] w-full mx-auto sm:w-[250px] text-[#000] text-[18px] lg:text-[22px] font-semibold rounded-[10px] border border-[#C5A313] bg-[#FDD01F] sm:mt-[30px]' onClick={() => (window.location.href = "https://tally.so/r/3xlVor")}>Apply Now</button>
        </div>
      </div>
      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/website-background.png" alt='background' className='absolute z-[0] w-full top-0 object-cover' loading='lazy' />
    </div>
  )
}
