import React from 'react'
import { AISvg, AlertSvg, AttendanceSvg, ChannelSvg, ChatSvg, CheckinSvg, CrmSvg, DocSvg, FoodSvg, FormSvg, HelpdeskSvg, HrmSvg, IntegrationSvg, LeaveSvg, PayrollSvg, PeopleSvg, ProjectSvg, ReportSvg, SettingSvg, StandupCallSvg, TaskSvg, TimeSvg } from '../svg'

export default function Roadmap() {
    const [position, setPosition] = React.useState(window.innerWidth <= 645);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);
    return (
        <div className='mt-[30px] sm:mt-[60px] relative px-[20px] sm:px-0'>
            <h4 className='text-[#0F0200] font-bold text-[24px] sm:text-[40px] xl:text-[50px] text-center'>Modules Release Roadmap</h4>
            <div className='flex flex-wrap gap-[30px] sm:gap-[50px] items-center justify-center sm:w-8/12 mx-auto mt-[20px] sm:mt-[50px] text-center'>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#FF3B3030] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <ChannelSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#FF3B30] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Channels</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#B2580030] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <ProjectSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#B25800] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Project</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#18829A30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <TaskSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#18829A] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Task</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#3E8D2630] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <ChatSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#3E8D26] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Chat</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#A58A0D30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <PeopleSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#A58A0D] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>People</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#2276DE30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <AlertSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#2276DE] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Alert</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#A58A0D30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <IntegrationSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#A58A0D] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Integration</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#18829A30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <ReportSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#18829A] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Report</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                {/* <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#32ADE630] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <OrgChartSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#32ADE6] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Org Chart</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div> */}
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#FF2D5530] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <StandupCallSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#FF2D55] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Standup Call</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#5856D630] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <SettingSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#5856D6] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Settings</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#1EB94130] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <CheckinSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#1EB941] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Check In & <br /> Check Out </div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#9D2BD630] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <DocSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#9D2BD6] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Docs</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(Available)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#1252A030] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <CrmSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#1252A0] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>CRM</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(February)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#30B0C730] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <HelpdeskSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#30B0C7] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Help Desk</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(March)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#EC535930] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <FoodSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#EC5359] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Canteen <br /> Management</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(April)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#70219130] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <AISvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#702191] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>AI Agent</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(May)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#2276DE30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <FormSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#2276DE] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Form <br /> Builder</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(June)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#5856D630] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <LeaveSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#5856D6] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Leave</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(July)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#B2580030] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <TimeSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#B25800] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Time</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(August)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#18829A30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <AttendanceSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#18829A] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Attendance <br /> Management</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(September)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#3E8D2630] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <PayrollSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#3E8D26] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>Payroll</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(October)</h5>
                    </div>
                </div>
                <div>
                    <div className='size-[80px] sm:size-[120px] rounded-[10px] sm:rounded-[14px] bg-[#A58A0D30] flex flex-col justify-center items-center'>
                        <div className='flex shrink-0'>
                            <HrmSvg size={position ? "20" : "28" }/>
                        </div>
                        <div className='text-[#A58A0D] text-[12px] sm:text-[15px] font-semibold mt-[10px]'>HRMS</div>
                    </div>
                    <div className='mt-[10px] text-[#000] text-[12px] sm:text-[15px] font-medium text-center'>
                        <h5>(November)</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
