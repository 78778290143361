import React from 'react'
import { Link } from 'react-scroll';
import DocsContent from './docs-content';
import Comments from './comments';
import NewDocument from './new-document';
import Export from './export1';
import CreateTable from './create-a-table';
import AddMedia from './add-media';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Docs', id: 'docs' },
        { title: 'Comments', id: 'comments' },
        { title: 'New Document', id: 'new-document' },
        { title: 'Export', id: 'export' },
        { title: 'Create a Table', id: 'create-a-table' },
        { title: 'Add Media', id: 'add-media' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                offset={-100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>Workfast Docs enables teams to create, store, and collaborate on documents seamlessly. From project plans to meeting notes, easily create, edit, and manage files within Workfast.ai with seamless sharing and exporting.</p>
                </div>
                <div id='docs'><DocsContent /></div>
                <div id='comments'><Comments/></div>
                <div id='new-document'><NewDocument/></div>
                <div id='export'><Export/></div>
                <div id='create-a-table'><CreateTable/></div>
                <div id='add-media'><AddMedia/></div>
            </div>
        </div>
    )
}
