import React from 'react'

export default function Footer({showFooter, setIsClickPay}) {

    // const calculateTimeLeft = () => {
    //     const storedExpiry = localStorage.getItem("expiryOneToOneTime");
    //     let expiryOneToOneTime;

    //     if (storedExpiry) {
    //         expiryOneToOneTime = new Date(storedExpiry);
    //     } else {
    //         expiryOneToOneTime = new Date();
    //         expiryOneToOneTime.setHours(expiryOneToOneTime.getHours() + 48);
    //         localStorage.setItem("expiryOneToOneTime", expiryOneToOneTime.toISOString());
    //     }

    //     const now = new Date();
    //     const difference = expiryOneToOneTime - now;

    //     let timeLeft = {
    //         hours: Math.floor(difference / (1000 * 60 * 60)),
    //         minutes: Math.floor((difference / 1000 / 60) % 60),
    //         seconds: Math.floor((difference / 1000) % 60),
    //     };

    //     if (difference <= 0) {
    //         timeLeft = { hours: 0, minutes: 0, seconds: 0 };
    //     }

    //     return timeLeft;
    // };

    // const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

    // React.useEffect(() => {
    //     const timerId = setInterval(() => {
    //         setTimeLeft(calculateTimeLeft());
    //     }, 1000);

    //     return () => clearInterval(timerId); // Cleanup the interval on component unmount
    // }, []);

    // const { hours, minutes, seconds } = timeLeft;

    return (
        <div className={`fixed bottom-0 w-full z-[40] bg-[#000]/[50%] border border-[#FDD01F] rounded-t-[14px] transition-all duration-500 ${showFooter ? "max-h-full py-[20px]" : "max-h-0"}`} style={{ boxShadow: "0px 0px 15px 0px #FDD01F33" }}>
            <div className='w-auto sm:w-11/12 lg:w-8/12 mx-auto flex flex-col sm:flex-row gap-[20px] justify-center px-[20px] sm:px-0'>
                {/* <div>
                    <h4 className='text-[#FDD01F] font-bold text-[24px]'>Free</h4>
                    <div className='flex gap-[10px] mt-[10px] sm:mt-[20px] text-[#FFF] font-semibold text-[20px]'>
                        <h5>Offer Ends In:- </h5>
                        <span className='text-[#FFF] font-bold'>{String(hours).padStart(2, "0")}</span> : <span className='text-[#FFF] font-bold'>{String(minutes).padStart(2, "0")}</span> : <span className='text-[#FFF] font-bold'>{String(seconds).padStart(2, "0")}</span>
                    </div>
                </div> */}
                <div className='flex justify-center items-center'>
                    <button className='py-[8px] lg:py-[10px] px-[14px] w-full mx-auto lg:w-[350px] text-[#000] text-[12px] sm:text-[18px] font-semibold rounded-[10px] border border-[#C5A313] bg-[#FDD01F]' onClick={() => { setIsClickPay(true) }} style={{ boxShadow: "0px 0px 22px 0px #FDD01F" }}>Book Now For Demo</button>
                </div>
            </div>
        </div>
    )
}
