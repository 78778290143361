import React from 'react'
import banner from "../../../../images/confidential/disclaimer.png";
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3 } from "react-icons/tb";

export default function Models() {
    // const [position, setPosition] = React.useState(window.innerWidth);

    // React.useEffect(() => {
    //     window.onresize = function () {
    //         setPosition(window.innerWidth);
    //     };
    // }, []);
    return (
        <div className='mt-[20px] sm:mt-[60px] relative text-center px-[20px] xl:px-0 bg-gradient-to-b from-[#EEF9FF] via-[#EAECFF] to-[#FFEAFF] py-[20px] sm:py-[50px] flex flex-col items-center'>
            <h4 className='text-[#121B2BE5] text-[24px] sm:text-[32px] lg:text-[50px] font-semibold'>Disclaimer</h4>
            <h5 className='text-[#121B2BCC] text-[14px] sm:text-[20px] lg:text-[24px] font-medium'>Investing in a franchise is not an investment in WorkFast.ai. Franchisees do not hold shares, ownership, or <br className='hidden xl:block'/> IP rights in the company. Instead, they secure a franchise license to sell WorkFast.ai in their Location.</h5>
            <img src={banner} alt='banner' />
            <div className='flex flex-wrap gap-[12px] sm:gap-[24px] items-center justify-center'>
                <div className='bg-[#FFFFFF33] w-[314px] lg:w-[384px] h-[400px] rounded-[32px] p-[13px]'>
                    <div className='bg-gradient-to-bl from-[#FFCEB2] via-[#FFB2F3] to-[#B2D1FF] p-[1px] rounded-[20px] h-full'>
                        <div className='bg-[#FFFFFF] p-[23px] flex flex-col gap-[12px] items-center rounded-[20px] h-full'>
                            <TbCircleNumber1 className='text-[#140C48E5] text-[20px]'/>
                            <h4 className='text-[#140C48E5] text-[24px] font-semibold'>Franchise Investor Model</h4>
                            <p className='text-[#140C48E5] text-[14px] lg:text-[16px] font-normal text-start'>For individuals who have money but lack the time or expertise to run the franchise. Investors can monitor progress via dashboards while hiring an experienced operator from our operators to manage daily sales and execution. You can interview and then select one of the operator who is in synergy.</p>
                        </div>
                    </div>
                </div>
                <div className='bg-[#FFFFFF33] w-[314px] lg:w-[384px] h-[400px] rounded-[32px] p-[13px]'>
                    <div className='bg-gradient-to-bl from-[#FFCEB2] via-[#FFB2F3] to-[#B2D1FF] p-[1px] rounded-[20px] h-full'>
                        <div className='bg-[#FFFFFF] p-[23px] flex flex-col gap-[12px] items-center rounded-[20px] h-full'>
                            <TbCircleNumber2 className='text-[#140C48E5] text-[20px]'/>
                            <h4 className='text-[#140C48E5] text-[24px] font-semibold'>Franchise Operator Model</h4>
                            <p className='text-[#140C48E5] text-[14px] lg:text-[16px] font-normal text-start'>For individuals who have the passion and skills to execute but lack capital. WorkFast.ai will match qualified operators with investors who have applied, or funding may be provided through the WorkFast.ai Fund to support execution-driven operators.You can interview and then select one of the Franchise Investor who is in synergy.</p>
                        </div>
                    </div>
                </div>
                <div className='bg-[#FFFFFF33] w-[314px] lg:w-[384px] h-[350px] sm:h-[400px] rounded-[32px] p-[13px]'>
                    <div className='bg-gradient-to-bl from-[#FFCEB2] via-[#FFB2F3] to-[#B2D1FF] p-[1px] rounded-[20px] h-full'>
                        <div className='bg-[#FFFFFF] p-[23px] flex flex-col gap-[12px] items-center rounded-[20px] h-full'>
                            <TbCircleNumber3 className='text-[#140C48E5] text-[20px]'/>
                            <h4 className='text-[#140C48E5] text-[24px] font-semibold'>Franchise Investor and <br className='hidden lg:block'/> Operator Model</h4>
                            <p className='text-[#140C48E5] text-[14px] lg:text-[16px] font-normal text-start'>This model is designed for those who want to invest and also operate the franchise. Investors with capital can set up their own team and actively run the business while benefiting from WorkFast.ai’s structured support and growth model.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
