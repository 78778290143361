import React from 'react'
import { BuildSpanSvg, NumberBgSvg } from '../../../confidential/component/svg';
import { LocationSvg, MasterSvg, RevenueSvg, StrategicSvg } from '../svg';

export default function LocationRights() {
    const [position, setPosition] = React.useState(window.innerWidth);
    const [startValue, setStartValue] = React.useState(null);
    const headerRef = React.useRef(null);

    const points = [
        { number: "01", colour: "#FFDAD8", },
        { number: "02", colour: "#FFEED4", },
        { number: "03", colour: "#FFF6D4", },
        { number: "04", colour: "#E0FFE8", },
    ]

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    React.useEffect(() => {
        if (headerRef.current) {
            const startValue = headerRef.current.getBoundingClientRect().left;
            setStartValue(startValue)
        }
    }, []);
    return (
        <div className='mt-[20px] sm:mt-[60px] relative text-center px-[20px] xl:px-0'>
            <div className='sm:w-11/12 xl:w-10/12 mx-auto bg-[#7A8A2414] rounded-[32px] p-[13px]'>
                <div className='w-full py-[32px] px-[10px] sm:px-0 rounded-[20px] bg-[#FFFFFFCC] flex flex-col items-center'>
                    <div>
                        <LocationSvg size={position >= 645 ? "125" : "60"} />
                    </div>
                    <h5 className='text-[#000000E5] text-[24px] sm:text-[36px] font-bold'>Location Rights</h5>
                    <p className='text-[#2B310DCC] font-medium text-[14px] sm:text-[20px] mt-[12px]'>Franchisees get rights to sell WorkFast.ai subscriptions within their designated Location or assigned territory .</p>
                    <p className='text-[#2B310DCC] font-medium text-[14px] sm:text-[20px] mt-[18px]'>Price Adjustments for High-Potential Regions like Business-heavy areas , For example, Bangalore <br className='hidden xl:block' /> IT hubs, SEZ's Mumbai corporate zones, Hyderabad startup clusters will have premium pricing</p>
                </div>
            </div>
            <div className='mt-[20px] sm:mt-[40px]'>
                <div className='relative max-w-[300px] sm:max-w-[350px] lg:max-w-[442px] mx-auto'>
                    <h3 ref={headerRef} className='text-[#181F38] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Franchise Fee Covers</h3>
                    <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[${startValue}px] sm:-left-[30px] z-0`}>
                        <BuildSpanSvg colour="#007AFF" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                    </div>
                </div>
                <div className='mt-[20px] sm:mt-[40px] flex flex-col gap-[32px] items-start px-[20px] sm:px-0 xl:w-7/12 mx-auto'>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start lg:items-center'>
                        <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[0]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"} />
                            </div>
                            <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[0].number}</div>
                        </div>
                        <div>
                            <p className='text-[#181F38] font-semibold text-[18px] sm:text-[26px] text-start'>Right to market and sell WorkFast.ai in the selected Location for one year</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start lg:items-center'>
                        <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[1]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"} />
                            </div>
                            <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[1].number}</div>
                        </div>
                        <div>
                            <p className='text-[#181F38] font-semibold text-[18px] sm:text-[26px] text-start'>Access to discounted pricing on licenses</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start lg:items-center'>
                        <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[2]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"} />
                            </div>
                            <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[2].number}</div>
                        </div>
                        <div>
                            <p className='text-[#181F38] font-semibold text-[18px] sm:text-[26px] text-start'>Training, certification, and continuous support</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start lg:items-center'>
                        <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[3]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"} />
                            </div>
                            <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[3].number}</div>
                        </div>
                        <div>
                            <p className='text-[#181F38] font-semibold text-[18px] sm:text-[26px] text-start'>Sales, marketing, and lead generation insights material.</p>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className='mt-[20px] sm:mt-[40px] text-[#2B2B2B] text-[24px] sm:text-[34px] lg:text-[54px] font-bold'>Franchise Investment Model</h4>
            <p className='mt-[11px] text-[#2B2B2B] text-[18px] sm:text-[29px] font-semibold'>Country-Wise Master Franchise</p>
            <div className='sm:w-11/12 lg:w-8/12 mx-auto mt-[20px] sm:mt-[40px] text-[#000000] text-[13px] sm:text-[17px] font-semibold'>
                <div className='grid grid-cols-2 border-[#7ABA8A] border-t border-x rounded-t-[22px] w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#C7FFD5] border-r border-[#A0A0A0] rounded-tl-[22px] text-[#23BD4A] font-bold'>
                        <p>Region</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center bg-[#C5FFFC] rounded-tr-[22px] text-[#00C4BB] font-bold'>
                        <p>Master Franchise Investment (USD)</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border w-full'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>USA, Canada, UK, Australia,Europe</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>$1 Million+</p>
                    </div>
                </div>
                <div className='grid grid-cols-2 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center'>
                        <p>Asia, Middle East, Africa</p>
                    </div>
                    <div className='py-[12px] sm:py-[18px] lg:py-[25px] flex justify-center items-center border-l border-[#A0A0A0]'>
                        <p>$500,000 - $1 Million</p>
                    </div>
                </div>
            </div>
            <div className='mt-[20px] sm:mt-[40px]'>
                <div className='grid sm:grid-cols-2 gap-[20px] w-11/12 mx-auto'>
                    <div className='bg-[#FFFFFF] rounded-[16px] p-[8px]' style={{ boxShadow: "0px 1px 3px 0px #0E094814" }}>
                        <div className='w-full py-[24px] bg-[#FF95001A] rounded-[12px] flex flex-col justify-center items-center'>
                            <div>
                                <MasterSvg size={position >= 645 ? "81" : "50"}/>
                            </div>
                            <h5 className='text-[#03276D] font-bold text-[20px] sm:text-[22px] lg:text-[26px]'>Master Franchise Rights</h5>
                        </div>
                        <div className='py-[24px]'>
                            <p className='text-[#1E1744CC] font-normal text-[14px] sm:text-[16px]'>Investors gain exclusive country-wide rights to market, sell, and expand <br className='hidden sm:block'/> WorkFast.ai franchise operations.</p>
                        </div>
                    </div>
                    <div className='bg-[#FFFFFF] rounded-[16px] p-[8px]' style={{ boxShadow: "0px 1px 3px 0px #0E094814" }}>
                        <div className='w-full py-[24px] bg-[#FFF1F7] rounded-[12px] flex flex-col justify-center items-center'>
                            <div>
                                <StrategicSvg size={position >= 645 ? "81" : "50"}/>
                            </div>
                            <h5 className='text-[#03276D] font-bold text-[20px] sm:text-[22px] lg:text-[26px]'>Strategic Growth Support</h5>
                        </div>
                        <div className='py-[24px]'>
                            <p className='text-[#1E1744CC] font-normal text-[14px] sm:text-[16px]'>WorkFast.ai provides dedicated business expansion guidance and priority <br className='hidden sm:block'/> lead sharing for master franchise partners.</p>
                        </div>
                    </div>
                    <div className='bg-[#FFFFFF] rounded-[16px] p-[8px] sm:col-span-2' style={{ boxShadow: "0px 1px 3px 0px #0E094814" }}>
                        <div className='w-full py-[24px] bg-[#34C7591A] rounded-[12px] flex flex-col justify-center items-center'>
                            <div>
                                <RevenueSvg size={position >= 645 ? "81" : "50"}/>
                            </div>
                            <h5 className='text-[#03276D] font-bold text-[20px] sm:text-[22px] lg:text-[26px]'>Revenue Model</h5>
                        </div>
                        <div className='py-[24px]'>
                            <p className='text-[#1E1744CC] font-normal text-[14px] sm:text-[16px]'>Master franchisees earn from Location-level franchise sales, direct license sales,and regional expansion partnerships.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
