import React from 'react'
import banner from "../../../../../../videos/userguide/docs/docs2.mov";

export default function Comments() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Comments</h3>
        <video src={banner} autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Workfast’s Comments feature enables seamless collaboration with @mentions, real-time updates, and threaded discussions for clear communication. Easily resolve, edit, or delete comments to keep feedback organized and actionable.</p>
        </div>
    </div>
  )
}
