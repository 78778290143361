const mode = process.env.REACT_APP_MODE ?? "STAGE"; // DEV STAGE UAT LIVE

const API = {};

API.HOST = "";
API.DETAILS = "";
API.BUTTON = "";
API.INVESTORS = "";
API.RESELLER = "";
API.CREATE_TASK = "";
API.PAYMENT = "";
API.KEY = "rzp_live_PHD5tJ258iR0HC";
API.PAYMENT_SUCCESS = "";
API.PAYMENT_CLOSED = "";
API.NEODOVE = "https://c399744a-850e-42fc-a65d-cbc2d8f9c34c.neodove.com/integration/custom/cc7835e8-9b5e-4c5c-a9f7-9ebec04d2220/leads"



if (mode === "LOCAL") { 
  API["HOST"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_mail.php";

  API["DETAILS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/personal_assistence_details.php";

  API["BUTTON"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["CREATE_TASK"] = "https://oneappdev.hifrds.com/api/v3/task/createTask";

  API["GOOGLE_LEADS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_google_leads.php";
  
  API["PAYMENT"] = "https://oneappdev.hifrds.com/api/v4/user/payment";

  API["PAYMENT_SUCCESS"] = "https://oneappdev.hifrds.com/api/v4/user/ticketSuccess";

  API["PAYMENT_CLOSED"] = "https://oneappdev.hifrds.com/api/v4/user/paymentClosed";

}

if (mode === "DEV") {
  API["HOST"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_mail.php";
  
  API["DETAILS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/personal_assistence_details.php";

  API["BUTTON"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["CREATE_TASK"] = "https://oneappdev.hifrds.com/api/v3/task/createTask";

  API["GOOGLE_LEADS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_google_leads.php";
  
  API["PAYMENT"] = "https://oneappdev.hifrds.com/api/v4/user/payment";

  API["PAYMENT_SUCCESS"] = "https://oneappdev.hifrds.com/api/v4/user/ticketSuccess";

  API["PAYMENT_CLOSED"] = "https://oneappdev.hifrds.com/api/v4/user/paymentClosed";

}

if (mode === "STAGE") {
  API["HOST"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/register_mail.php";

  API["DETAILS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/personal_assistence_details.php";

  API["BUTTON"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["CREATE_TASK"] = "https://oneappdev.hifrds.com/api/v3/task/createTask";

  API["GOOGLE_LEADS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_google_leads.php";

  API["PAYMENT"] = "https://oneappdev.hifrds.com/api/v4/user/payment";

  API["PAYMENT_SUCCESS"] = "https://oneappdev.hifrds.com/api/v4/user/ticketSuccess";

  API["PAYMENT_CLOSED"] = "https://oneappdev.hifrds.com/api/v4/user/paymentClosed";

}

if (mode === "UAT") {
  API["HOST"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/register_mail.php";

  API["DETAILS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/personal_assistence_details.php";

  API["BUTTON"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["CREATE_TASK"] = "https://oneappbackendlive.hifrds.com/api/v3/task/createTask";

  API["GOOGLE_LEADS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_google_leads.php";

  API["PAYMENT"] = "https://oneappdev.hifrds.com/api/v4/user/payment";

  API["PAYMENT_SUCCESS"] = "https://oneappdev.hifrds.com/api/v4/user/ticketSuccess";

  API["PAYMENT_CLOSED"] = "https://oneappdev.hifrds.com/api/v4/user/paymentClosed";

}

if (mode === "LIVE") {
  API["HOST"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/register_mail.php";

  API["DETAILS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/personal_assistence_details.php";
    
  API["BUTTON"] = "https://k8s.onlyapp.in/node/api/v1/website/addLog";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["CREATE_TASK"] = "https://oneappbackendlive.hifrds.com/api/v3/task/createTask";

  API["GOOGLE_LEADS"] = "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/register_google_leads.php";

  API["PAYMENT"] = "https://oneappdev.hifrds.com/api/v4/user/payment";

  API["PAYMENT_SUCCESS"] = "https://oneappdev.hifrds.com/api/v4/user/ticketSuccess";

  API["PAYMENT_CLOSED"] = "https://oneappdev.hifrds.com/api/v4/user/paymentClosed";

}

module.exports = API;
