import React, { useEffect, useState } from "react";
import { BuildSpanSvg } from "../../../confidential/component/svg";
import Play from "../../../../../components/images/campaign_deals/Play.svg";
import VideoThumb from "../../../../../components/images/campaign_deals/thumb.png";



export default function Banner({ setIsClickPay, setVideoId }) {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const calculateTimeLeft = () => {
        const storedExpiry = localStorage.getItem("expiryCampaignTime");
        let expiryCampaignTime;

        if (storedExpiry) {
            expiryCampaignTime = new Date(storedExpiry);
        } else {
            expiryCampaignTime = new Date();
            expiryCampaignTime.setHours(expiryCampaignTime.getHours() + 48);
            localStorage.setItem("expiryCampaignTime", expiryCampaignTime.toISOString());
        }

        const now = new Date();
        const difference = expiryCampaignTime - now;

        let timeLeft = {
            hours: Math.floor(difference / (1000 * 60 * 60)),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };

        if (difference <= 0) {
            timeLeft = { hours: 0, minutes: 0, seconds: 0 };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timerId); // Cleanup the interval on component unmount
    }, []);

    const { hours, minutes, seconds } = timeLeft;

    const calculateDay = () => {
        const storedDay = localStorage.getItem("CampaignDays");
        if (hours <= 48 && hours >= 24) {
            localStorage.setItem("CampaignDays", "02");
        } else if (hours <= 24) {
            localStorage.setItem("CampaignDays", "01");
        } else {
            localStorage.setItem("CampaignDays", "00");
        }
        return storedDay;
    };
    const [days, setDays] = useState(calculateDay());

    useEffect(() => {
        setDays(calculateDay());
    }, [hours]);

    useEffect(() => {
        const video = document.getElementById("banner-video");
        if (!video) return;

        let observer;
        let hasInteracted = false;

        const isVideoInView = () => {
            const rect = video.getBoundingClientRect();
            return rect.top >= 0 && rect.bottom <= window.innerHeight;
        };

        const attemptPlay = () => {
            if (isVideoInView()) {
                video.play().catch(error => {
                    console.error("Autoplay prevented:", error);
                });
            }
        };

        const handleUserInteraction = () => {
            if (!hasInteracted) {
                hasInteracted = true;
                attemptPlay();
            }
            window.removeEventListener("click", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
        };

        window.addEventListener("click", handleUserInteraction);
        window.addEventListener("touchstart", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);

        observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && hasInteracted) {
                    video.play();
                } else {
                    video.pause();
                }
            },
            { threshold: 0.5 }
        );

        observer.observe(video);

        return () => {
            window.removeEventListener("click", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            if (observer) observer.disconnect();
        };
    }, []);

    return (
        <div className='mt-[30px] sm:mt-[80px] flex flex-col gap-[16px] lg:gap-[24px] justify-center items-center mx-auto relative overflow-hidden z-[15] px-[20px] sm:px-0'>
            <h1 className='text-[#180833] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[64px] font-bold text-center'>One Platform for Everything</h1>
            <h2 className='text-[#180833] text-[16px] sm:text-[22px] lg:text-[28px] xl:text-[44px] font-bold text-center'>Collaboration, Tasks Management, CRM & Helpdesk.</h2>
            <div className='relative'>
                <h3 className='text-[#180833] text-[14px] sm:text-[16px] lg:text-[26px] xl:text-[32px] font-semibold text-center z-[1]'>Transform Your Workflow - Book a Live Demo for Just ₹99!</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[40px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#AF52DE" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "20"} />
                </div>
            </div>
            <div className="w-10/12 mx-auto grid lg:grid-cols-2 gap-[16px] lg:gap-[40px] justify-center">
                <div className="w-full h-[100px] sm:h-[150px] lg:h-[180px] xl:h-[150px] bg-[#FDD01F33] border border-[#FDD01F] rounded-[12px] py-[14px] sm:py-[20px] px-[14px] sm:px-[20px] my-auto">
                    <div className="text-center font-bold text-[12px] sm:text-[24px]">100+ businesses have booked a demo! 🚀</div>
                    <div className="w-full flex justify-center items-center px-[20px] xl:px-0 mt-[20px]">
                        <button className='py-[8px] lg:py-[10px] px-[14px] w-full mx-auto sm:w-[350px] text-[#000] text-[12px] sm:text-[18px] font-semibold rounded-[10px] border border-[#C5A313] bg-[#FDD01F]' style={{ boxShadow: "0px 0px 22px 0px #FDD01F" }} onClick={() => { setIsClickPay(true) }}>Book Now For Demo ₹99/- Only</button>
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <div className="text-center font-semibold text-[16px] sm:text-[22px] text-[#007AFF]">Grab It Fast – Only {days} Days Left! 👇</div>
                    <div className="flex gap-[20px] sm:gap-[32px] items-end justify-center mt-[16px] smmt-[30px]">
                        <div className="flex flex-col gap-[10px] items-center justify-center">
                            <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                                Days
                            </div>
                            <div className="text-[#1EB941] text-[28px] sm:text-[40px] xl:text-[50px] font-bold">
                                {String(days).padStart(2, "0")}
                            </div>
                        </div>
                        <div className="h-[30px] xl:h-[50px] bg-[#8D8D8D] w-[2px] mb-[10px] sm:mb-[30px] flex shrink-0"></div>
                        <div className="flex flex-col gap-[10px] items-center justify-center">
                            <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                                Hours
                            </div>
                            <div className="text-[#1EB941] text-[28px] sm:text-[40px] xl:text-[50px] font-bold">
                                {String(hours).padStart(2, "0")}
                            </div>
                        </div>
                        {/* <img
                            src={dots}
                            alt=""
                            className="mb-[15px] sm:mb-[35px] w-[4px] xl:w-[8px]"
                        /> */}
                        <div className="text-[#000] text-[28px] sm:text-[40px] xl:text-[50px] font-semibold mb-[3px] sm:mb-[5px]">:</div>

                        <div className="flex flex-col gap-[10px] items-center justify-center">
                            <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                                Minutes
                            </div>
                            <div className="text-[#1EB941] text-[28px] sm:text-[40px] xl:text-[50px] font-bold">
                                {String(minutes).padStart(2, "0")}
                            </div>
                        </div>
                        <div className="text-[#000] text-[28px] sm:text-[40px] xl:text-[50px] font-semibold mb-[3px] sm:mb-[5px]">:</div>
                        {/* <img
                            src={dots}
                            alt=""
                            className="mb-[15px] sm:mb-[35px] w-[4px] xl:w-[8px]"
                        /> */}
                        <div className="flex flex-col gap-[10px] items-center justify-center">
                            <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                                Seconds
                            </div>
                            <div className="text-[#1EB941] text-[28px] sm:text-[40px] xl:text-[50px] font-bold">
                                {String(seconds).padStart(2, "0")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-[32px] sm:w-[650px] h-[200px] sm:h-[450px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px]'>
                <div className='relative cursor-pointer focus:outline-none rounded-[9px] sm:rounded-[17px] xl:rounded-[21px' style={{ boxShadow: "0px 0px 15px 0px #000000" }}>
                    <img src={Play} alt='play' className='absolute size-[40px] sm:size-[50px] sm:h-auto top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2' onClick={() => setVideoId("kuv7ARN2gH0")} />
                    <img src={`${VideoThumb}`} alt='profile' className='object-cover sm:w-[650px] sm:h-[350px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px]' />
                </div>
            </div>
        </div>
    );
}
