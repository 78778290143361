import React from 'react'
import { AlertSvg, ChannelSvg, ChatSvg, CheckinSvg, DocSvg, IntegrationSvg, PeopleSvg, ProjectSvg, ReportSvg, SettingSvg, StandupCallSvg, TaskSvg } from '../svg'

export default function Modules() {
    const [position, setPosition] = React.useState(window.innerWidth <= 645);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);
    return (
        <div className='mt-[30px] sm:mt-[60px] relative px-[20px] sm:px-0' id='module'>
            <h4 className='text-[#0F0200] font-bold text-[24px] sm:text-[40px] xl:text-[50px] text-center'>Available Modules</h4>
            <div className='grid sm:grid-cols-2 gap-[40px] mt-[15px] sm:mt-[30px] lg:mt-[50px] sm:w-11/12 lg:w-9/12 mx-auto'>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#FF3B3030] shrink-0 mt-[10px] sm:mt-0'>
                        <ChannelSvg size={position ? "20" : "28" } />
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Channel:</span> Create and manage channels for team communications.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#B2580030] shrink-0 mt-[10px] sm:mt-0'>
                        <ProjectSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Projects:</span> Create, manage, and track projects easily.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#18829A30] shrink-0 mt-[10px] sm:mt-0'>
                        <TaskSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Tasks:</span> Create, assign and track tasks effortlessly.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#A58A0D30] shrink-0 mt-[10px] sm:mt-0'>
                        <PeopleSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>People:</span> Easily manage and connect your team in one view.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#3E8D2630] shrink-0 mt-[10px] sm:mt-0'>
                        <ChatSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Chat:</span> Instanly chat and collaborate seemlessly.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#9D2BD630] shrink-0 mt-[10px] sm:mt-0'>
                        <DocSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Docs:</span> Organize, share and collaborate on documents in one place.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#2276DE30] shrink-0 mt-[10px] sm:mt-0'>
                        <AlertSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Alert:</span> Receive and manage alerts for important updates.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#18829A30] shrink-0 mt-[10px] sm:mt-0'>
                        <ReportSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Report:</span> Generate and analyze report with ease.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#A58A0D30] shrink-0 mt-[10px] sm:mt-0'>
                        <IntegrationSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Integration:</span> Connect Your favorite tools and streamline workflows effortlessly.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#5856D630] shrink-0 mt-[10px] sm:mt-0'>
                        <SettingSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Settings:</span> Customize your preferences to make the app work for you.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#FF2D5530] shrink-0 mt-[10px] sm:mt-0'>
                        <StandupCallSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Standup Call:</span> Stay aligned with your team thorugh quick, sturctured daily standup call.</h5>
                    </div>
                </div>
                <div className='flex gap-[15px] items-center'>
                    <div className='size-[40px] sm:size-[56px] flex justify-center items-center rounded-[12px] bg-[#1EB94130] shrink-0 mt-[10px] sm:mt-0'>
                        <CheckinSvg size={position ? "20" : "28" }/>
                    </div>
                    <div>
                        <h5 className='text-[#252432] text-[14px] sm:text-[18px] font-medium mt-[12px]'><span className='font-semibold text-[16px] lg:text-[20px]'>Check In - Check Out:</span> Track your work hours seemlessly and stay on top of your schedule.</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
