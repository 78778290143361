import React from 'react'

export default function Structure() {

    return (
        <div className='mt-[20px] sm:mt-[60px] relative text-center'>
            <p className='text-[#2B2B2B] text-[24px] sm:text-[34px] font-bold px-[20px] sm:px-0'>Structure of the Partnership Model</p>
            <div className='px-[20px] sm:px-[40px] mt-[20px] sm:mt-[36px] overflow-x-scroll'>
                <div className='w-[950px] text-[#000000] text-[13px] sm:text-[17px] font-semibold overflow-x-scroll mx-auto'>
                    <div className='grid grid-cols-4 border border-[#A0A0A0] w-full rounded-t-[22px]'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#007AFF33] border-r border-[#A0A0A0] rounded-tl-[22px] text-[#007AFF] font-bold'>
                            <p>Role</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#5856D633] border-r border-[#A0A0A0]  text-[#5856D6] font-bold'>
                            <p>Responsibilities</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#AF52DE33] border-r border-[#A0A0A0]  text-[#AF52DE] font-bold'>
                            <p>Investment</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center bg-[#FF2D5533] rounded-tr-[22px]  text-[#FF2D55] font-bold'>
                            <p>Returns</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 border-[#A0A0A0] border-b border-x w-full'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                            <p>Franchise Investor <br className='hidden xl:block'/> (Silent Partner)</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>Funds setup, provides strategic inputs, but does not manage operations</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>80%-100% of investment</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>Fixed % of revenue/profit <br className='hidden xl:block'/> (e.g., 60%)</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 border-[#A0A0A0] border-b border-x w-full rounded-b-[22px]'>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center'>
                            <p>Franchise Operator <br className='hidden xl:block'/> (Active Partner)</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>Runs operations, drives growth, ensures customer success</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>Little to no investment</p>
                        </div>
                        <div className='py-[12px] sm:py-[18px] lg:py-[25px] px-[10px] flex justify-center items-center border-l border-[#A0A0A0]'>
                            <p>Lower % initially <br className='hidden xl:block'/> (e.g., 40%) but can increase based on <br className='hidden xl:block'/> performance</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-[20px] sm:mt-[40px] px-[20px] xl:px-0'>
            <p className='text-[#2B2B2B] text-[24px] sm:text-[40px] font-bold txet-center'>Operator Reward</p>
            <p className='mt-[12px] text-[#000000] text-[14px] sm:text-[24px] font-medium text-center'>Operators can increase their stake based on performance. If targets are met consistently, the
             <br className='hidden xl:block'/> operator’s share can increase over time, ensuring long-term motivation.</p>

            </div>
        </div>
    )
}
