import React from 'react'
import { IoIosCheckmark } from "react-icons/io";
import axios from 'axios';
import API from '../../../utils/API';
import Header from '../../header';
import Footer from '../../footer';

export default function Pricing() {
    const [toggleValue, setToggleValue] = React.useState('Month');

    const [price, setPrice] = React.useState(null);
    const [currencySymbol, setCurrencySymbol] = React.useState('$');

    React.useEffect(() => {
        setPrice(toggleValue === "Month" ? 10 : 8);
    }, [toggleValue]);

    React.useEffect(() => {
        const fetchLocationAndSetPrice = async () => {
            try {
                const locationResponse = await axios.get('https://ipapi.co/json/');
                const countryCode = locationResponse.data.country_code;

                if (countryCode !== 'IN') {
                    setCurrencySymbol('$');
                    setPrice(toggleValue === "Month" ? 10 : 8);
                }
            } catch (error) {
                console.error('Error fetching location data', error);
            }
        };

        fetchLocationAndSetPrice();
    }, [toggleValue]);

    const handleClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": toggleValue === "Month" ? "MONTH_STARTER" : "YEAR_STARTER"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": toggleValue === "Month" ? "MONTH_BASIC" : "YEAR_BASIC"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    const pricing = [
        { content: "Price", free: "Free", $6: "$10" },
        { content: "No of users", free: "Unlimited 🚀", $6: "Unlimited 🚀" },
        { content: "File storage", free: "1 GB ", $6: "Unlimited 🚀" },
        { content: "Mobile Apps", free: "✅", $6: "✅" },
        { content: "Projects (Web Only)", free: "Upto 20 Projects", $6: "Unlimited 🚀" },
        { content: "Chats", free: "Unlimited 🚀", $6: "Unlimited 🚀" },
        { content: "Collaboarators ", free: "Unlimited 🚀", $6: "Unlimited 🚀" },
        { content: "Workflow Stages", free: "Unlimited 🚀", $6: "Unlimited 🚀" },
        { content: "Task Creation", free: "Monthly 200 tasks", $6: "Unlimited 🚀" },
        { content: "Sharing documents and media", free: "Unlimited 🚀", $6: "Unlimited 🚀" },
        { content: "Guest users", free: "Max 1 / Only in Specific channel", $6: "Free upto 3 (4 guests charged as 1 licence) Only in Specific channel" },
        { content: "Guest collabs", free: "Max 1", $6: "Free upto 3 ( 4 guests charged as 1 licence )" },
        { content: "Guest access", free: "Only Viewer", $6: "Only Viewer, Viewer + Editor on Request" },
        { content: "Real time updates", free: "✅", $6: "✅" },
        { content: "Channel creation", free: "Unlimited 🚀", $6: "Unlimited 🚀" },
        { content: "Feedback Channel", free: "✅", $6: "✅" },
        { content: "Chat - Add to task", free: "Upto 200 Tasks", $6: "Unlimited 🚀" },
        { content: "Project view", free: "✅", $6: "✅" },
        { content: "People view", free: "✅", $6: "✅" },
        { content: "Date view", free: "✅", $6: "✅" },
        { content: "Priority view", free: "✅", $6: "✅" },
        { content: "Workspace Creation", free: "One workspace", $6: "Max 4" },
        { content: "Universal search", free: "✅", $6: "✅" },
        { content: "Customer support", free: "✅", $6: "✅" },
    ]

    return (
        <div className='flex flex-col justify-start items-center w-full relative px-0 h-full overflow-hidden'>
            <div className='relative z-[20] w-full'><Header /></div>
            <div className='relative w-full mt-[60px] lg:mt-[40px]'>
                <div className='text-center mt-[20px] lg:mt-[60px] relative z-[10] px-[20px] sm:px-0'>
                    <h2 className='text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[64px] text-[#060606] font-black'>Our Pricing</h2>
                </div>
                <div className='p-[3px] lg:p-2 bg-[#0000001A] rounded-[28px] flex items-center mt-[10px] lg:mt-[20px] relative z-[10] w-[136px] lg:w-[195px] mx-auto'>
                    <div className={`p-[3px] lg:p-2 ${toggleValue === "Month" ? " bg-gradient-to-bl from-[#FDD01F] via-[#F8C240] to-[#F8861B] text-[#2A2200]" : "bg-transparent text-[#353535]"} cursor-pointer rounded-[20px]`} onClick={() => { setToggleValue("Month") }}>
                        <h3 className='mx-[10px] font-normal text-[12px] lg:text-[16px]'>Monthly</h3>
                    </div>
                    <div className={`p-[3px] lg:p-2 ${toggleValue === "Year" ? " bg-gradient-to-bl from-[#FDD01F] via-[#F8C240] to-[#F8861B] text-[#2A2200]" : "bg-transparent text-[#353535]"} cursor-pointer rounded-[20px]`}>
                        <h3 className='mx-[10px] font-normal text-[12px] lg:text-[16px]' onClick={() => { setToggleValue("Year") }}>Yearly</h3>
                    </div>
                </div>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/website-background.png" alt='workfast' loading='lazy' className='absolute z-[0] w-full top-0' />
            </div>
            <div className='grid sm:grid-cols-2 lg:flex gap-[31px] items-center justify-center relative z-[10] mt-[30px] lg:mt-[50px] px-[20px] lg:px-0'>
                <div className="group">
                    <div className={`p-[20px] lg:px-[32px] lg:py-[38px] w-full lg:w-[440px] rounded-[10px] lg:rounded-[30px] relative overflow-hidden bg-[#FFFFFF] border-[2px] border-[#FED321] flex flex-col gap-[14px]`}>
                        <div>
                            <h2 className='text-[#3D3D3D] text-[22px] sm:text-[25px] lg:text-[28px] font-bold relative z-[10]'>Basic</h2>
                            <h3 className='text-[#3D3D3D] text-[12px] sm:text-[14px] lg:text-[16px] font-medium relative z-[10]'>For small teams</h3>
                        </div>
                        <div>
                            <h2 className='text-[#3D3D3D] text-[36px] lg:text-[44px] font-bold relative z-[10]'>Free <span className='text-[#818181] font-normal text-[12px] sm:text-[14px] lg:text-[16px]'>Unlimited</span></h2>
                        </div>
                        <button className='w-full py-[10px] bg-[#FFDD094D] border-[#FFDD09] border rounded-[11px] text-[#383000] font-medium text-[16px] relative z-[10]' onClick={(e) => { handleSubmit(e) }}>Get Started</button>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>1 workspace</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium ttext-[15px] lg:ext-[17px]'>5 users</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>1 Project</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>1 GB storage </h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Monthly 200 tasks</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Mobile Apps</h3>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className={`p-[20px] lg:px-[32px] lg:py-[38px] w-full lg:w-[440px] rounded-[10px] lg:rounded-[30px] relative overflow-hidden bg-[#FFFFFF] border-[2px] border-[#FED321] flex flex-col gap-[14px]`}>
                        <div>
                            <h2 className='text-[#3D3D3D] text-[22px] sm:text-[25px] lg:text-[28px] font-bold relative z-[10]'>Starter Pack</h2>
                            <h3 className='text-[#3D3D3D] text-[12px] sm:text-[14px] lg:text-[16px] font-medium relative z-[10]'>For advanced teams & businesses</h3>
                        </div>
                        <div className='flex gap-[10px] items-center'>
                            <h2 className='text-[#3D3D3D] text-[30px] sm:text-[36px] lg:text-[44px] font-bold relative z-[10]'>{currencySymbol}{price}
                            </h2>
                            <div>
                                <h2 className='text-[#3D3D3D] font-medium text-[12px] sm:text-[14px] lg:text-[16px] relative z-[10]'>{toggleValue === "Month" ? "Per user / month" : "Per user / month"}</h2>
                                {/* <h2 className='text-[#3D3D3D] font-medium text-[12px] sm:text-[14px] lg:text-[16px] relative z-[10]'>{toggleValue === "Month" ? "billed monthly" : "billed monthly"}</h2> */}
                            </div>
                        </div>
                        <button className='w-full py-[10px] bg-[#FFDD094D] border-[#FFDD09] border rounded-[11px] text-[#383000] font-medium text-[16px] relative z-[10]' onClick={(e) => { handleClick(e) }}>Get Started</button>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Upto 4 workspaces</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Unlimited users</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Unlimited Projects</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Unlimited storage </h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Unlimited Tasks Creation</h3>
                        </div>
                        <div className='flex gap-[5px] items-center relative z-[1]'>
                            <IoIosCheckmark className='text-[#FFDD09] text-[22px] lg:text-[27px]' />
                            <h3 className='text-[#3D3D3D] font-medium text-[15px] lg:text-[17px]'>Unlimited Add to tasks - Chat</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-11/12 bg-[#FFF] border border-[#CECEEA] rounded-[10px] lg:rounded-[30px] p-[1px] my-[30px] lg:my-[50px]' style={{boxShadow : "0px 0px 4px 0px #00000040"}}>
                <div className={`px-[22px] py-[18px] sm:px-[30px] sm:py-[24px] lg:px-[53px] lg:pt-[33px] pb-[12px] w-full overflow-x-auto mx-auto rounded-[10px] lg:rounded-[30px] relative overflow-hidden flex flex-col gap-[14px]`}>
                    <h2 className='text-center text-[#060606] text-[20px] sm:text-[26px] lg:text-[36px] font-semibold relative z-[10]'>Plans and features</h2>
                    <div className='lg:mt-[15px] w-full'>
                        <div className='py-[20px] grid grid-cols-3 justify-between items-center text-[#060606] text-[14px] sm:text-[20px] lg:text-[24px] font-bold border-b-[1px] border-[#CECEEA]'>
                            <h2>Content</h2>
                            <h2 className='text-center'>Basic - Free</h2>
                            <h2 className='text-center lg:text-end lg:ml-auto w-[100px] sm:w-[220px] lg:mr-[35px]'>Starter Pack</h2>
                        </div>
                        {pricing.map((each, index) => (
                            <div key={index} className={`py-[20px] grid grid-cols-3 justify-between items-center text-[#313131] text-[14px] sm:text-[16px] lg:text-[20px] font-medium border-[#CECEEA] ${index === pricing.length - 1 ? "" : "border-b-[1px]"}`}>
                                <h2 className='text-[#060606] font-semibold'>{each?.content}</h2>
                                <h2 className='text-center text-[12px] sm:text-[16px] lg:text-[20px]'>{each?.free}</h2>
                                <h2 className={`text-center ml-auto w-[100px] sm:w-[220px] text-[12px] sm:text-[16px] lgtext-[20px]`}>{each?.$6}</h2>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
