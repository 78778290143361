import React from 'react'
import { Link } from 'react-scroll';
import Calendar from './calendar';
import ConnectYourAccount from './connect-your-account';
import Events from './events';
import CreateEvent from './create-a-event';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Connect your account', id: 'connect-your-account' },
        { title: 'Calendar', id: 'calendar' },
        { title: 'Events', id: 'events' },
        { title: 'Create a Event', id: 'create-a-event' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                offset={-100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>Connect Workfast.ai with your favorite tools to streamline workflows. Integrate third-party apps effortlessly and enhance productivity.</p>
                </div>
                <div id='connect-your-account'><ConnectYourAccount/></div>
                <div id='calendar'><Calendar /></div>
                <div id='events'><Events/></div>
                <div id='create-a-event'><CreateEvent/></div>
            </div>
        </div>
    )
}
