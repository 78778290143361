import React from 'react'
import Header from './header'
import Footer from './footer'
import Banner from './banner'
import Features from './features'
import Pincode from './what_is_pincode'
import WhoOwnPincode from './who_own_pincode'
import WhyOwnPincode from './why_own_pincode'
import HowWorks from './how_works'
import Potential from './potential'
import WhyWorkfast from './why_workfast'
import Responsibility from './responsibility'
import Faq from './faq'
import DontMissOut from './dont_miss_out'
import Future from './future'


export default function Franchise() {

    return (
        <div className='relative overflow-hidden'>
            <Header />
            <Banner />
            <Features />
            <Pincode />
            <WhoOwnPincode />
            <WhyOwnPincode />
            <HowWorks />
            <Potential />
            <WhyWorkfast />
            <Responsibility />
            <Faq />
            <DontMissOut/>
            <Future />
            <Footer />
        </div>
    )
}
