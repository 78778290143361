import React from 'react'
import { Step1Svg, Step2Svg, Step3Svg, Step4Svg, Step5Svg } from '../svg'

export default function HowWorks() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='w-11/12 xl:w-10/12 mx-auto mt-[30px] sm:mt-[60px] relative flex flex-col items-center'>
            <h4 className='text-[#2B2B2B] text-[24px] sm:text-[40px] lg:text-[50px] xl:text-[60px] font-bold text-center'>How the WorkFast.ai SaaS <br className='hidden sm:block' /> Franchise Works?</h4>
            <div className='mt-[20px] sm:mt-[50px] grid sm:grid-cols-2 gap-[18px] w-full xl:w-9/12 mx-auto justify-center'>
                <div className='bg-[#FF3B301A] w-full lg:h-[320px] rounded-[26px] border border-[#FF3B30] flex flex-col items-center py-[21px]' style={{ boxShadow: "0px 0px 0px 0px #FF3B3033" }}>
                    <h5 className='text-[#FF3B30] text-[22px] sm:text-[26px] lg:text-[30px] font-bold'>Step 01</h5>
                    <div className='mt-[12px] sm:mt-[18px]'>
                        <Step1Svg size={position <= 645 ? "45" : position <= 768 ? "56" : "86"} />
                    </div>
                    <h5 className='text-[#870700] font-bold text-[18px] sm:text-[20px] lg:text-[22px] mt-[12px] text-center'>Choose & Block Your Location</h5>
                    <p className='mt-[7px] text-[#92302B] text-[12px] sm:text-[14px] lg:text-[16px] font-medium text-center leading-[30px]'> 📍 Block it before someone else does. <br /> 📍 Select a Metro, Tier 2, or Tier 3 City.</p>
                </div>
                <div className='bg-[#E988001A] w-full lg:h-[320px] rounded-[26px] border border-[#FF9500] flex flex-col items-center py-[21px] px-[5px]' style={{ boxShadow: "0px 0px 0px 0px #E9880033" }}>
                    <h5 className='text-[#FF9500] text-[22px] sm:text-[30px] font-bold'>Step 02</h5>
                    <div className='mt-[12px] sm:mt-[18px]'>
                        <Step2Svg size={position <= 645 ? "45" : position <= 768 ? "56" : "86"} />
                    </div>
                    <h5 className='text-[#583400] font-bold text-[18px] sm:text-[20px] lg:text-[22px] mt-[12px] text-center'>Apply & Get Access to the <br className='sm:hidden'/> Franchise Kit</h5>
                    <p className='mt-[7px] text-[#79582A] text-[12px] sm:text-[14px] lg:text-[16px] font-medium text-center leading-[30px] px-[5px]'>📝 Submit your details to receive Confidential Franchise Information, including ROI and responsibilities.</p>
                </div>
                <div className='bg-[#FFCC001A] w-full lg:h-[320px] rounded-[26px] border border-[#FFCC00] flex flex-col items-center py-[21px]' style={{ boxShadow: "0px 0px 0px 0px #FFCC00" }}>
                    <h5 className='text-[#FFCC00] text-[22px] sm:text-[30px] font-bold'>Step 03</h5>
                    <div className='mt-[12px] sm:mt-[18px]'>
                        <Step3Svg size={position <= 645 ? "45" : position <= 768 ? "56" : "86"} />
                    </div>
                    <h5 className='text-[#917917] font-bold text-[18px] sm:text-[20px] lg:text-[22px] mt-[12px] text-center'>Pay the Annual Franchise Fee</h5>
                    <p className='mt-[7px] text-[#917E32] text-[12px] sm:text-[14px] lg:text-[16px] font-medium text-center leading-[30px] px-[5px]'>💰 Secure the rights to market, sell, and implement WorkFast.ai in your Location for one year.</p>
                </div>
                <div className='bg-[#34C7591A] w-full lg:h-[320px] rounded-[26px] border border-[#34C759] flex flex-col items-center py-[21px]' style={{ boxShadow: "0px 0px 0px 0px #34C759" }}>
                    <h5 className='text-[#34C759] text-[22px] sm:text-[30px] font-bold'>Step 04</h5>
                    <div className='mt-[12px] sm:mt-[18px]'>
                        <Step4Svg size={position <= 645 ? "45" : position <= 768 ? "56" : "86"} />
                    </div>
                    <h5 className='text-[#005F18] font-bold text-[18px] sm:text-[20px] lg:text-[22px] mt-[12px] text-center'>Get Certified & Start Selling</h5>
                    <p className='mt-[7px] text-[#3B7B4B] text-[12px] sm:text-[14px] lg:text-[16px] font-medium text-center leading-[30px] px-[5px]'>🎓 Complete your WorkFast.ai Partner Certification <br/> 🎓 Gain credibility and onboard businesses. </p>
                </div>
                <div className='sm:col-span-2 bg-[#32ADE61A] w-full lg:h-[320px] rounded-[26px] border border-[#32ADE6] flex flex-col items-center py-[21px]' style={{ boxShadow: "0px 0px 0px 0px #32ADE6" }}>
                    <h5 className='text-[#32ADE6] text-[22px] sm:text-[30px] font-bold'>Step 05</h5>
                    <div className='mt-[12px] sm:mt-[18px]'>
                        <Step5Svg size={position <= 645 ? "45" : position <= 768 ? "56" : "86"} />
                    </div>
                    <h5 className='text-[#003E5A] font-bold text-[18px] sm:text-[20px] lg:text-[22px] mt-[12px] text-center'>Sell & Earn Recurring Revenue</h5>
                    <p className='mt-[7px] text-[#2B5568] text-[12px] sm:text-[14px] lg:text-[16px] font-medium text-center leading-[30px] px-[5px]'>💰 Each sale and renewal adds to your income. <br/> 💰 Expand your customer base and scale your business. </p>
                </div>
            </div>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[40px]' style={{ boxShadow: "5px 5px 100px 0px #FFDD0933" }}>Check First SaaS Availability & Apply Now</button> */}
        </div>
    )
}
