import React from 'react'

export default function DontMissOut() {
  return (
    <div className='mt-[30px] sm:mt-[60px] w-11/12 mx-auto bg-[#212A780A] p-[20px] sm:p-[30px] lg:p-[60px] rounded-[14px] sm:rounded-[24px] relative overflow-hidden'>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/bg-1.png" alt='background' className='absolute z-0 left-0 top-0' />
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/bg-2.png" alt='background' className='absolute z-0 right-0 top-0' />
        <div className='relative z-[55] flex flex-col items-center'>
            <h4 className='text-[#000000] text-[18px] sm:text-[32px] lg:text-[40px] xl:text-[50px] font-bold text-center'>🔥 Don't Miss Out – Become a Workfast.ai <br className='hidden lg:block'/> Franchisee Today!</h4>
            <h5 className='mt-[14px] sm:mt-[28px] text-[#000000] text-[12px] sm:text-[20px] lg:text-[26px] font-medium text-center'>Workfast.ai is revolutionizing the Future of Work – and you can be a pioneer in your Location. Own <br className='hidden lg:block'/> your Franchise, build your business, and lead the AI revolution!</h5>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[14px] sm:mt-[28px]' style={{boxShadow: "5px 5px 100px 0px #FFDD0933"}}>Apply for Franchise Now</button> */}
        </div>
    </div>
  )
}
