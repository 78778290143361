import React from 'react'
import banner from "../../../../../../videos/userguide/docs/docs4.mov";

export default function Export() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Export</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[4px]'></video>
      <div className='mt-[6px]'>
        <p className='text-[#000000] text-[14px] font-medium'>The Export feature in Workfast allows users to save and share documents in multiple formats with ease. Whether exporting as PDF, Word, or plain text, documents retain their formatting and structure. This ensures seamless sharing with external teams, clients, or for offline use. With just a few clicks, users can securely download and distribute their work.</p>
        <div className='mt-[11px] flex flex-col gap-[7px]'>
          <div className='flex gap-[4px] items-start'>
            <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
            <p className='text-[#000000] text-[14px] font-normal'>Exports as PDF, HTML and Other Formats</p>
          </div>
        </div>
      </div>
    </div>
  )
}
