import React from 'react'
import { LuPlus, LuMinus } from "react-icons/lu";

export default function Faq() {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isOpenIndex, setIsOpenIndex] = React.useState(0);

    const faq = [
        { question: 'How much can I earn as a WorkFast.ai Franchisee?', answer: "Your earnings depend on the number of licenses sold and renewed in your Location. Franchisees can generate steady, recurring monthly revenue as businesses continue to use WorkFast.ai." },
        { question: 'Do I need technical expertise?', answer: "No, WorkFast.ai provides comprehensive training and support to help you succeed in sales and implementation." },
        { question: 'Can I expand to multiple SaaS?', answer: "Yes! Franchisees who perform well can expand to additional Tiers to increase their revenue." },
        { question: 'How does the franchise fee work?', answer: "The franchise fee grants you the rights to sell and implement WorkFast.ai in your Location for one year, along with access to training, support, and discounted licenses." },
        { question: 'What kind of support does WorkFast.ai provide?', answer: "We provide sales & technical training, marketing materials, lead generation support, and technical assistance." },
        { question: 'How long does it take to start earning revenue?', answer: "It depends on your sales efforts, but many franchisees start closing deals within the first 30-60 days after onboarding and training." },
        { question: 'Can I sell additional services along with WorkFast.ai?', answer: "Yes! You can bundle WorkFast.ai licenses with consulting, implementation, and training services to increase your revenue potential." },
    ]
    
    return (
        <div className='mt-[30px] sm:mt-[60px] relative px-[20px] sm:px-0'>
            <h2 className='text-[28px] sm:text-[40px] xl:text-[60px] text-[#0F0200] font-bold text-center'>Frequently Asked Questions (FAQ)</h2>
            <div className='mt-[20px] sm:mt-[40px] lg:mt-[50px] mx-auto sm:w-8/12 xl:w-1/2'>
                {faq.map((each, index) => (
                    <div key={index} className={`px-[26px] py-[21px] border-[#303030] flex-col gap-[8px] sm:gap-[12px] rounded-[18px] mt-[20px] ${isOpen && index === isOpenIndex ? "bg-[#F8F8F8]" :"bg-[#FFF] border border-[#ABABAB0F]"}`} style={{ boxShadow : index !== isOpenIndex && "0px 1px 4px 0px #8F8F8F33"}}>
                        <div className='flex justify-between items-center gap-[10px]'>
                            <h2 className='text-[16px] lg:text-[20px] text-[#272727] font-semibold'>{each.question}</h2>
                            {isOpen && index === isOpenIndex ? (
                                <LuMinus className={`text-[#272727] text-[16px] lg:text-[20px] cursor-pointer transition-opacity duration-500 flex shrink-0 ${isOpen && index === isOpenIndex ? "opacity-100" : "opacity-0"}`} onClick={() => { setIsOpen(false) }} />
                            ) : (
                                <LuPlus className={`text-[#8F8F8F] text-[16px] lg:text-[20px] cursor-pointer transition-opacity duration-500 flex shrink-0 ${isOpen && index === isOpenIndex ? "opacity-0" : "opacity-100"}`} onClick={() => { setIsOpen(true); setIsOpenIndex(index) }} />
                            )}
                        </div>
                        <div className={`overflow-hidden transition-all duration-500 ${isOpen && index === isOpenIndex ? 'max-h-screen opacity-100 mt-[22px]' : 'max-h-0 opacity-0'}`}>
                            <p className='text-[12px] lg:text-[18px] text-[#5C5C5C] font-normal'>{each?.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
