import React from 'react'
import { CheckImgSvg } from '../svg'

export default function Responsibility() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='bg-[#EDFAFE] py-[20px] sm:py-[40px] lg:py-[60px] mt-[30px] sm:mt-[60px] px-[20px] xl:px-0 relative flex flex-col items-center'>
            <h4 className='text-[#0F0200] text-[24px] sm:text-[32px] lg:text-[54px] font-bold text-center'>Your Franchise Rights & <br className='hidden sm:block' /> Responsibilities</h4>
            <h5 className='mt-[14px] sm:mt-[28px] text-[#0F0200] text-[16px] sm:text-[20px] lg:text-[24px] font-normal text-center'>Apply now to receive our Confidential Franchise Kit, which includes</h5>
            <div className='mt-[20px] sm:mt-[56px]'>
                <div className='flex gap-[14px] sm:gap-[28px] items-center'>
                    <div>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"} />
                    </div>
                    <p className='text-center text-[#181F38] text-[18px] sm:text-[28px] font-semibold'>Investment & ROI insights</p>
                </div>
                <div className='flex gap-[14px] sm:gap-[28px] items-center mt-[20px] sm:mt-[42px]'>
                    <div>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"} />
                    </div>
                    <p className='text-center text-[#181F38] text-[18px] sm:text-[28px] font-semibold'>Responsibilities & earning potential</p>
                </div>
                <div className='flex gap-[14px] sm:gap-[28px] items-center mt-[20px] sm:mt-[42px]'>
                    <div>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"} />
                    </div>
                    <p className='text-center text-[#181F38] text-[18px] sm:text-[28px] font-semibold'>Support & training details</p>
                </div>
                <div className='flex gap-[14px] sm:gap-[28px] items-center mt-[20px] sm:mt-[42px]'>
                    <div>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"} />
                    </div>
                    <p className='text-center text-[#181F38] text-[18px] sm:text-[28px] font-semibold'>Full franchise terms</p>
                </div>
            </div>
            <h6 className='text-[#2B3857] text-[16px] sm:text-[24px] font-medium mt-[20px] sm:mt-[56px] text-center'> <span className='text-[#EB5056]'>*</span> Franchisees own the rights to market and sell WorkFast.ai in their Location for one year but do <br className='hidden xl:block' /> not own shares in WorkFast.ai or its intellectual property (IP).</h6>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[32px]' style={{ boxShadow: "5px 5px 100px 0px #FFDD0933" }}>Apply for Franchise Now</button> */}
        </div>
    )
}
