import React from 'react'
import { WorkfastLogo } from '../../../../../header/svg';

export default function Mobile() {

    return (
        <div className={`relative z-[60] w-full`}>
            <div className={`w-full flex justify-between items-center p-[20px] z-[50] border-[1px] border-[#CECEEA] relative`}>
                <a title='Workfast' href='/'>
                    <div className='px-[10px] py-[6px] border border-[#CECEEA] rounded-[16px] bg-[#FFF]' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }}>
                        <WorkfastLogo width="110" height="26" />
                    </div>
                </a>
                <div className='flex gap-[10px] items-center'>
                    <button className='border border-[#C5A313] bg-[#FDD01F] rounded-[12px] h-[34px] px-[10px] text-[#2A2200] text-[12px] font-semibold' onClick={(e) => ( window.location.href = "https://tally.so/r/3xlVor" )}>Apply Now</button>
                </div>
            </div>
        </div>
    )
}
