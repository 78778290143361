import React from 'react'
import { CheckImgSvg } from '../svg'

export default function PartnerDriven() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='bg-[#EDFAFE] px-[20px] xl:px-0 py-[20px] sm:py-[44px] lg:py-[73px] mt-[20px] sm:mt-[60px] relative flex flex-col items-center'>
            <h4 className='text-[#0F0200] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[54px] font-bold text-center'>The “Partner-Driven Franchise” <br className='hidden xl:block' /> Model</h4>
            <h5 className='mt-[14px] sm:mt-[28px] text-[#0F0200] text-[16px] sm:text-[20px] lg:text-[24px] font-normal text-center'>Find the Best Franchise Model for You!</h5>
            <h6 className='text-[#2B3857] text-[16px] sm:text-[20px] lg:text-[24px] font-medium mt-[24px] smmt-[56px] text-center'>Instead of a single-owner franchise, this model creates a structured partnership <br className='hidden sm::block' /> agreement where:</h6>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[32px]' style={{ boxShadow: "5px 5px 100px 0px #FFDD0933" }}>Learn More & Apply</button> */}
            <div className='mt-[20px] sm:mt-[56px]'>
                <div className='flex gap-[14px] sm:gap-[28px] items-start'>
                    <div className='mt-[5px]'>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"}/>
                    </div>
                    <p className='text-start text-[#181F38] text-[20px] sm:text-[28px] font-semibold'>A Franchise Investor (Silent Partner) funds the franchise setup and <br className='hidden sm:block'/> initial costs.</p>
                </div>
                <div className='flex gap-[14px] sm:gap-[28px] items-start mt-[20px] sm:mt-[42px]'>
                    <div className='mt-[5px]'>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"}/>
                    </div>
                    <p className='text-start text-[#181F38] text-[20px] sm:text-[28px] font-semibold'>A Franchise Operator (Active Partner) runs daily operations and <br className='hidden sm:block'/> grows the business.</p>
                </div>
                <div className='flex gap-[14px] sm:gap-[28px] items-start mt-[20px] sm:mt-[42px]'>
                    <div className='mt-[5px]'>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"}/>
                    </div>
                    <p className='text-start text-[#181F38] text-[20px] sm:text-[28px] font-semibold'>A Performance-Linked Structure ensures fairness and accountability.</p>
                </div>
                <div className='flex gap-[14px] sm:gap-[28px] items-start mt-[20px] sm:mt-[42px]'>
                    <div className='mt-[5px]'>
                        <CheckImgSvg size={position <= 645 ? "21" : "41"}/>
                    </div>
                    <p className='text-start text-[#181F38] text-[20px] sm:text-[28px] font-semibold'>Full franchise terms</p>
                </div>
            </div>
        </div>
    )
}
