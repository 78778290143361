import React from 'react'
import { FinancialSvg, HandshakeSvg, PackagingSvg, StatisticSvg } from '../svg'


export default function WhyWorkfast() {
    return (
        <div className='mt-[30px] sm:mt-[60px] bg-[#FFF8FB] p-[20px] sm:py-[40px] lg:py-[60px] sm:pl-[60px] 2xl:p-[67px] 2xl:pb-[87px] text-center relative'>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/cave.png" alt='cave' className='absolute top-[30px] left-1/2 -translate-x-1/2 z-0 hidden xl:block' loading='lazy'/>
            <h4 className='text-[#0F0200] font-bold text-[32px] sm:text-[40px] lg:text-[54px]'>Why Workfast.ai?</h4>
            <h5 className='text-[#0F0200] text-[18px] sm:text-[24px] font-normal mt-[10px]'>Businesses across industries are adopting AI-driven productivity tools to stay <br className='hidden lg:block'/> competitive. Workfast.ai provides</h5>
            <div className='mt-[20px] sm:mt-[40px] lg:mt-[80px] flex gap-[20px] mx-auto justify-center xl:justify-start 2xl:justify-center flex-wrap sm:flex-wrap sm:overflow-x-scroll relative z-[55]'>
                <div className='p-[16px] bg-[#FFFFFF] rounded-[16px] h-[167px] w-[300px] shrink-0 flex flex-col gap-[8px] items-center sm:items-start justify-center'>
                    <div>
                        <StatisticSvg />
                    </div>
                    <h5 className='text-[#000000] text-[19px] font-bold'>High Demand</h5>
                    <h6 className='text-[#000000] text-[16px] font-normal text-start'>Businesses need AI-powered collaboration tools.</h6>
                </div>
                <div className='p-[16px] bg-[#FFFFFF] rounded-[16px] h-[167px] w-[300px] shrink-0 flex flex-col gap-[8px] items-center sm:items-start justify-center xl:mt-[110px]'>
                    <div>
                        <FinancialSvg />
                    </div>
                    <h5 className='text-[#000000] text-[19px] font-bold'>Scalable Model</h5>
                    <h6 className='text-[#000000] text-[16px] font-normal text-start'>Start with one Location, expand as your business grows.</h6>
                </div>
                <div className='p-[16px] bg-[#FFFFFF] rounded-[16px] h-[167px] w-[300px] shrink-0 flex flex-col gap-[8px] items-center sm:items-start justify-center'>
                    <div>
                        <HandshakeSvg />
                    </div>
                    <h5 className='text-[#000000] text-[19px] font-bold'>Trusted SaaS Brand</h5>
                    <h6 className='text-[#000000] text-[16px] font-normal text-start'>A powerful alternative to Slack, Asana, Monday.com, etc...</h6>
                </div>
                <div className='p-[16px] bg-[#FFFFFF] rounded-[16px] h-[167px] w-[300px] shrink-0 flex flex-col gap-[8px] items-center sm:items-start justify-center xl:mt-[110px]'>
                    <div>
                        <PackagingSvg />
                    </div>
                    <h5 className='text-[#000000] text-[19px] font-bold'>Comprehensive Support</h5>
                    <h6 className='text-[#000000] text-[16px] font-normal text-start'>We provide training, resources & continous assistance.</h6>
                </div>
            </div>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[90px]' style={{boxShadow: "5px 5px 100px 0px #FFDD0933"}}>Apply Now & Secure Your Pincode</button> */}
        </div>
    )
}
