import React from 'react'
import { NumberBgSvg } from '../../../confidential/component/svg';
import { NinetySvg, SixToTweleSvg, YearTwoSvg } from '../svg';

export default function Roadmap() {
    const [position, setPosition] = React.useState(window.innerWidth);

    const points = [
        { number: "01", colour: "#FFDAD8", },
        { number: "02", colour: "#FFEED4", },
        { number: "03", colour: "#FFF6D4", },
        { number: "04", colour: "#E0FFE8", },
        { number: "05", colour: "#BDEBE9", },
        { number: "06", colour: "#B9F2FC", },
        { number: "07", colour: "#B6E7FD", },
        { number: "08", colour: "#C9E3FF", },
        { number: "09", colour: "#C9C8F7", },
        { number: "10", colour: "#F0D2FF", },
        { number: "11", colour: "#F6C7D0", },
        { number: "12", colour: "#F5DAB7", },
    ]

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='mt-[20px] sm:mt-[60px] relative text-center bg-[#FFF8FB] py-[15px] sm:py-[30px] px-[20px] sm:px-0'>
            <h4 className='text-[24px] sm:text-[32px] lg:text-[44px] xl:text-[60px] font-bold text-[#0F0200]'>Franchise Success Roadmap</h4>
            <div className='mt-[20px] sm:mt-[50px] flex flex-col items-center gap-[50px] mx-auto'>
                <div className='p-[12px] sm:p-[24px] bg-[#FFFFFFCC] rounded-[24px] sm:w-[600px]' style={{ boxShadow: "0px 4px 8px 0px #2406641F" }}>
                    <div className='flex justify-center'>
                        <NinetySvg size={position >= 645 ? "122" : "60"}/>
                    </div>
                    <p className='text-[#140C48E5] text-[18px] sm:text-[24px] font-semibold'>First 90 Days - Kickstarting Your Franchise</p>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[0]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[0].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Complete WorkFast.ai Training & Certification</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[1]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[1].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Set up your franchise outlet and branding in your Location</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[2]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[2].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Build initial business connections and partnerships</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[3]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[3].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Execute targeted marketing campaigns</p>
                        </div>
                    </div>
                </div>
                <div className='p-[12px] sm:p-[24px] bg-[#FFFFFFCC] rounded-[24px] sm:w-[600px]' style={{ boxShadow: "0px 4px 8px 0px #2406641F" }}>
                    <div className='flex justify-center'>
                        <SixToTweleSvg size={position >= 645 ? "122" : "60"}/>
                    </div>
                    <p className='text-[#140C48E5] text-[18px] sm:text-[24px] font-semibold'>6-12 Months - Scaling and Expanding</p>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[4]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[18px] sm:text-[27px] font-medium z-[1] relative'>{points[4].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[16px] sm:text-[20px] text-start'>Complete WorkFast.ai Training & Certification</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[5]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[18px] sm:text-[27px] font-medium z-[1] relative'>{points[5].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[16px] sm:text-[20px] text-start'>Set up your franchise outlet and branding in your Location</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[6]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[6].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Build initial business connections and partnerships</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[7]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[7].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Execute targeted marketing campaigns</p>
                        </div>
                    </div>
                </div>
                <div className='p-[12px] sm:p-[24px] bg-[#FFFFFFCC] rounded-[24px] sm:w-[600px]' style={{ boxShadow: "0px 4px 8px 0px #2406641F" }}>
                    <div className='flex justify-center'>
                        <YearTwoSvg size={position >= 645 ? "122" : "60"}/>
                    </div>
                    <p className='text-[#140C48E5] text-[18px] sm:text-[24px] font-semibold'>Year 2 & Beyond - Expansion & Leadership</p>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[8]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[8].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Complete WorkFast.ai Training & Certification</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[9]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[9].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Set up your franchise outlet and branding in your Location</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[10]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[10].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Build initial business connections and partnerships</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start sm:items-center mt-[20px]'>
                        <div className='relative size-[28px] sm:size-[35px] lg:size-[45px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={points[11]?.colour} size={position >= 1024 ? "45" : position >= 645 ? "35" : "28"} />
                            </div>
                            <div className='text-[#000000] text-[16px] sm:text-[27px] font-medium z-[1] relative'>{points[11].number}</div>
                        </div>
                        <div>
                            <p className='text-[#423C60] font-semibold text-[14px] sm:text-[20px] text-start'>Execute targeted marketing campaigns</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
