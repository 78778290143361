import React from 'react'
import { Link } from 'react-scroll';
import Signup from './signup';
import ContinueWithEmail from './continue-with-email';
import VerifyOtp from './verify-otp';
import EnterWorkSpaceName from './enter-workspace-name';
import CreateYourProfile from './create-your-profile';
import InviteTeamMembers from './invite-team-members';
import CreateFirstChannel from './create-first-channel';
import WorkspaceSetup from './workspace-setup';
import ExistingWorkspace from './existing-workspace';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        console.log(to);
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Signup/Sign-in', id: 'signup-signin' },
        { title: 'Create a New Workspace or Continue with Work Email', id: 'continue-with-email' },
        { title: 'Verify OTP', id: 'verify-otp' },
        { title: 'Existing Workspace', id: 'existing-workspace' },
        { title: 'Create New Workspace', id: 'enter-workspace-name' },
        { title: 'Create Your Profile', id: 'create-your-profile' },
        { title: 'Invite Team Members', id: 'invite-team-members' },
        { title: 'Create Your First Channel', id: 'create-your-first-channel' },
        { title: 'Workspace Setup Successful', id: 'workspace-setup-successful' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                offset={-100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>Welcome to Workfast.ai! Set up your account, invite your team, and create your workspace to start collaborating seamlessly.</p>
                </div>
                <div id='signup-signin'><Signup /></div>
                <div id='continue-with-email'><ContinueWithEmail/></div>
                <div id='verify-otp'><VerifyOtp/></div>
                <div id='existing-workspace'><ExistingWorkspace/></div>
                <div id='enter-workspace-name'><EnterWorkSpaceName/></div>
                <div id='create-your-profile'><CreateYourProfile/></div>
                <div id='invite-team-members'><InviteTeamMembers/></div>
                <div id='create-your-first-channel'><CreateFirstChannel/></div>
                <div id='workspace-setup-successful'><WorkspaceSetup/></div>
            </div>
        </div>
    )
}
