import React from 'react'


export default function Trusted() {
  return (
    <div className='flex flex-col gap-[20px] sm:gap-[32px] items-center justify-center w-full mt-[20px] sm:mt-[60px] px-[20px] sm:px-0'>
      <p className='text-[#000000] text-[24px] sm:text-[32px] lg:text-[47px] font-bold text-center'>Our Trusted Customers</p>
      <div class="flex overflow-hidden space-x-16 group">
        <div class="flex space-x-8 sm:space-x-16 animate-loop-scroll group-hover:paused">
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/tie-chennai.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/one-dot.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/vista.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/adept.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/naturals.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/rajalakshmi-institute.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
        </div>
        <div class="flex space-x-8 sm:space-x-16 animate-loop-scroll group-hover:paused" aria-hidden="true">
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/tie-chennai.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/one-dot.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/vista.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/adept.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/naturals.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/rajalakshmi-institute.webp" alt='workfast' loading='lazy' className='w-[100px] sm:w-auto max-w-none' width={300} height={300} />
        </div>
      </div>
    </div>
  )
}
