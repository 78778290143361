import React from 'react'
import banner from "../../../../../../videos/userguide/integration/integration4.mov";

export default function CreateEvent() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
      <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create a Event</h3>
      <video src={banner} autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[10px]'>
        {/* <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>The Create an Event feature in Workfast allows users to schedule and manage important meetings, deadlines, and milestones effortlessly. Users can set event details, invite participants, and add reminders to keep everyone aligned. With calendar integration and real-time notifications, teams stay informed and never miss key moments. Whether for project planning or team collaboration, Workfast makes event management simple and efficient. 🚀</p> */}
        <div className='mt-[22px] flex flex-col gap-[15px]'>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>1</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter Title</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>2</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Invite Guests or Co-workers</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>3</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Select Date and Time</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>4</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Give Description for meeting</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>5</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Toggle the Meet Link button</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>6</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Create to Start a meeting</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>7</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Cancel to go back</p>
          </div>
        </div>
      </div>
    </div>
  )
}
