import React, { useEffect, useState } from 'react'
import Banner from './banner'
import Modules from './modules'
import Trusted from './trusted'
import Feedback from './feedback'
import Roadmap from './roadmap'
import Header from './header'
import Features from './features'
import Mobile from './mobile'
import Footer from './footer'
import { GoAlert } from "react-icons/go";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import API from '../../../../utils/API'
import { ContactSvg, EmailSvg, ProfileSvg } from '../google_leads/form/svg';
import YouTube from 'react-youtube';



export default function CampaignDeals() {
    const [showFooter, setShowFooter] = useState(false);
    const [isClickPay, setIsClickPay] = useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const [isPay, setIsPay] = React.useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [position, setPosition] = React.useState(window.innerWidth <= 645);
    const [videoId, setVideoId] = useState(null);
    
        const opts = {
            playerVars: {
                autoplay: 1,
            },
        };

    const handleNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setName(e.target.value)
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        const email = e.target.value;
        const gmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === "") {
            setEmailError(false);
        }
        else if (!gmailRegex.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const handlePhoneNumberChange = (phone, country) => {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const dialCode = country?.country?.dialCode || "";
        const phoneWithoutPlus = phone.startsWith(`+${dialCode}`) ? phone.slice(dialCode.length + 1) : phone;
        let isValid = false;
        try {
            const parsedPhone = phoneUtil.parse(phone, country?.country?.iso2);
            isValid = phoneUtil.isValidNumber(parsedPhone);
        } catch (error) {
            isValid = false;
        }
        if (!isValid || phoneWithoutPlus === "") {
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false)
        }
        setPhoneNumber(phone);
    }

    const loadScript = async (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            document.body.appendChild(script);
        });
    }

    const handlePayment = async () => {
        try {
            setIsPay(true);
            const { data } = await axios.post(
                API.PAYMENT,
                {
                    name: name, email: email, mobile: phoneNumber, userCount: 1, subscriptionType: "", pageType: "one_to_one_demo", redirectLink: "https://workfast.ai/campaign/success"
                },
            );
            if (data === "nodomain") {
                alert("Domain url wrong");
                window.location.href = "/404";
            }
            else if (data?.statusCode === 200) razorPayment(data?.data);
            setIsClickPay(false);
            setName("");
            setEmail("");
            setPhoneNumber("");
            setIsPay(false);
        } catch (err) {
            var statusCode = err?.response?.status;
            if (statusCode === 404) setIsPay(false);
            else if (statusCode === 412) setIsPay(false);
            else if (statusCode === 410) setIsPay(false);
            else setIsPay(false);
        }
    };

    const razorPayment = async (purchaseData) => {
        if (!scriptLoaded) {
            await loadScript('https://checkout.razorpay.com/v1/checkout.js'); // Load the Razorpay script dynamically
            setScriptLoaded(true);
        }

        const options = {
            key: API.KEY, // Replace with your actual Razorpay API key
            amount: purchaseData?.totalAmt, // Amount in paise (e.g., 10000 paise = <span className="avenirnext">₹ </span>100)
            currency: 'INR',
            // name: "ACTC Events",
            description: 'Workfast Deals',
            // image: purchaseData?.shopImage,
            order_id: purchaseData?.id, // Replace with the generated order ID from your server
            callback_url: "https://oneappdev.hifrds.com/api/v4/user/ticketSuccess",
            // handler: (response) => {
            //     console.log(response); // Handle the payment response from Razorpay
            // },
            notes: {
                purchaseId: purchaseData?.id
            },
            prefill: {
                email: email,
                contact: phoneNumber,
                // 'method': 'card' //netbanking|card|upi|wallet
            },
            modal: {
                ondismiss: async () => {
                    await axios.post(API.PAYMENT_CLOSED, { id: purchaseData?.id });
                },
            },
            theme: {
                color: "#560095"
            },
            config: {
                display: {
                    hide: [
                        { method: 'paylater' },
                        { method: 'emi' },
                        { method: 'cardless_emi' },
                        { method: 'upi' },
                    ],
                }
            }
        };

        console.log(options);

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    useEffect(() => {
        const topSectionRef = document.getElementById("topSectionRef")
        if (!topSectionRef) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setShowFooter(!entry.isIntersecting); // Show footer when top section is NOT visible
            },
            { threshold: 0.3 } // Adjusted threshold (30% of the section needs to be in view)
        );

        observer.observe(topSectionRef);

        return () => {
            observer.unobserve(topSectionRef);
        };
    }, []);

    useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);

    return (
        <div className='relative overflow-hidden'>
            <div id="topSectionRef">
                <Header />
                <Banner setIsClickPay={setIsClickPay} setVideoId={setVideoId}/>
                <Features />
            </div>
            <Modules />
            <Roadmap />
            <Trusted />
            <Feedback setVideoId={setVideoId}/>
            <Mobile />
            <Footer showFooter={showFooter} setIsClickPay={setIsClickPay} />
            {isClickPay && (
                <div className="bg-[#000]/[70%] fixed inset-0 z-[100] h-screen w-full flex justify-center items-center">
                    <div className='w-[300px] md:w-[630px] bg-[#FFF] rounded-[16px] p-[20px] sm:p-[32px] relative z-[10]'>
                        <div className="text-[24px] text-[#000] font-semibold flex justify-between items-center">
                            <div>Personal Details</div><RxCross2 className="cursor-pointer" onClick={() => { setIsClickPay(false); setEmail(""); setPhoneNumber(""); setName("") }} />
                        </div>
                        <div className='grid sm:gap-x-5 gap-y-8 sm:gap-y-10 items-center mt-[15px] md:mt-[30px] w-full'>
                            <div className='w-full'>
                                <div className='flex items-center gap-1'>
                                    <div className='size-[28px] rounded-[6px] bg-[#FF950040] flex justify-center items-center'>
                                        <ProfileSvg size={position ? "19" : "23"} />
                                    </div>
                                    <h3 className='text-[#080808] text-[12px] md:text-[17px] font-medium'>Name*</h3>
                                </div>
                                <div className={`w-full h-[38px] md:h-[44px] border-[1px] border-[#CECEEA] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                                    <input type='text' className='w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[12px]' value={name} placeholder='Enter your name' onChange={(e) => { handleNameChange(e); }} />
                                </div>
                            </div>
                            <div className='w-full relative'>
                                <div className='flex items-center gap-1'>
                                    <div className='size-[28px] rounded-[6px] bg-[#FFCC0040] flex justify-center items-center'>
                                        <EmailSvg size={position ? "19" : "23"} />
                                    </div>
                                    <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>Company Email*</h3>
                                </div>
                                <div className={`w-full h-[38px] md:h-[44px] border-[1px] lg:py-[10px] mt-[10px] rounded-[8px] ${emailError ? "border-[1px] border-[#F92A4B]" : "border-[#CECEEA]"}`}>
                                    <input type='email' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[10px]' placeholder='Enter your company email' value={email} onChange={(e) => { handleEmailChange(e) }} />
                                </div>
                                {emailError && (
                                    <div className='absolute flex gap-[10px] items-center text-[12px] sm:text-[14px] text-red-700 font-medium -bottom-[23px] sm:-bottom-[28px]'>
                                        <GoAlert />
                                        <div>Please Enter Your Work Email</div>
                                    </div>
                                )}
                            </div>
                            <div className='w-full'>
                                <div className='flex items-center gap-1'>
                                    <div className='size-[28px] rounded-[6px] bg-[#34C75940] flex justify-center items-center'>
                                        <ContactSvg size={position ? "19" : "23"} />
                                    </div>
                                    <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>Phone Number*</h3>
                                </div>
                                <div className={`w-full h-[38px] md:h-[44px] border-[1px] border-[CECEEA] lg:py-[10px] mt-[10px] rounded-[8px] ${phoneNumberError ? "border-[1px] border-[#F92A4B]" : ""}`}>
                                    {/* <input type='text' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' placeholder='Enter your Phone number' value={phoneNumber} onChange={(e) => { handlePhoneNumberChange(e) }} /> */}
                                    <PhoneInput defaultCountry="in" value={phoneNumber} onChange={(phone, country) => { handlePhoneNumberChange(phone, country) }} className="w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[10px] booking" />
                                </div>
                            </div>
                        </div>
                        {isPay ?
                            <div class="loader"></div>
                            :
                            <button className={`w-full h-[38px] md:h-[44px] rounded-[8px] border-[1px] border-[#995900] bg-[#FDD01F] text-[#2A2200] text-[16px] font-medium mt-[30px] ${email === "" || emailError || phoneNumber === "" || phoneNumberError ? "opacity-30" : ""}`} disabled={email === "" || emailError || phoneNumber === "" || phoneNumberError} onClick={(e) => { handlePayment() }}>Buy Now</button>
                        }
                    </div>
                </div>
            )}
            {videoId && (
                <div className="fixed inset-0 bg-[#000000]/[80%] flex justify-center items-center text-center z-[100] cursor-pointer" onClick={() => setVideoId(null)}>
                    <div className="rounded-[10px] h-screen w-full flex items-center">
                        <div className="relative m-auto max-w-[300px] sm:max-w-[640px] w-full">
                            <YouTube videoId={videoId} opts={opts} className='feedback' />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
