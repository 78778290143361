import React from 'react'
import { DiagramSvg, GlobalSvg, JustTimeSvg, RemoteSvg } from '../svg'

export default function Potential() {
  return (
    <div className='mt-[30px] sm:mt-[60px] relative px-[20px] xl:px-0 py-[20px] sm:py-[50px] bg-[#EDFAFE] text-center'>
        <h4 className='text-[#0F0200] text-[24px] sm:text-[40px] font-bold'>The Market Potential for WorkFast.ai</h4>
        <h5 className='mt-[14px] sm:mt-[24px] text-[#0F0200] text-[14px] sm:text-[22px] font-medium'>AI-driven SaaS solutions are transforming industries worldwide. Businesses are <br className='hidden xl:block'/> adopting automated project management tools to improve
        <br className='hidden xl:block'/> workflows and productivity.</h5>
        <div className='mt-[20px] sm:mt-[50px] flex flex-wrap gap-[40px] items-center justify-center'>
            <div className='w-[295px] h-[185px] rounded-[14px] flex justify-center items-center flex-col gap-[6px] bg-[#FFF]' style={{ boxShadow: "0px 1px 14px 0px #00C7DA40" }}>
                <div>
                    <GlobalSvg />
                </div>
                <p className='text-[#2B3857] mt-[3px] text-[18px] font-bold'>Global SaaS Market</p>
                <p className='text-[#2B3857] text-[16px] font-medium'>Expected to exceed $900 billion <br/> by 2030.</p>
            </div>
            <div className='w-[295px] h-[185px] rounded-[14px] flex justify-center items-center flex-col gap-[6px] bg-[#FFF]' style={{ boxShadow: "0px 1px 14px 0px #00C7DA40" }}>
                <div>
                    <RemoteSvg />
                </div>
                <p className='text-[#2B3857] mt-[3px] text-[18px] font-bold'>Remote & Hybrid Work Growth</p>
                <p className='text-[#2B3857] text-[16px] font-medium'>Companies need AI-driven tools <br/> for collaboration.</p>
            </div>
            <div className='w-[295px] h-[185px] rounded-[14px] flex justify-center items-center flex-col gap-[6px] bg-[#FFF]' style={{ boxShadow: "0px 1px 14px 0px #00C7DA40" }}>
                <div>
                    <DiagramSvg />
                </div>
                <p className='text-[#2B3857] mt-[3px] text-[18px] font-bold'>Wide Industry Demand</p>
                <p className='text-[#2B3857] text-[16px] font-medium'>IT, consulting, e-commerce, real <br /> estate, healthcare, and more.</p>
            </div>
            <div className='w-[295px] h-[185px] rounded-[14px] flex justify-center items-center flex-col gap-[6px] bg-[#FFF]' style={{ boxShadow: "0px 1px 14px 0px #00C7DA40" }}>
                <div>
                    <JustTimeSvg />
                </div>
                <p className='text-[#2B3857] mt-[3px] text-[18px] font-bold'>B2B Subscription Model</p>
                <p className='text-[#2B3857] text-[16px] font-medium'>Predictable recurring revenue for <br/> franchisees.</p>
            </div>
        </div>
        <h6 className='mt-[20px] sm:mt-[50px] text-[#000000] text-[20px] sm:text-[32px] font-semibold'>Be part of the AI revolution—this is your opportunity!</h6>
        {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[28px]' style={{boxShadow: "5px 5px 100px 0px #FFDD0933"}}>Apply Now & Secure Your Pincode</button> */}
    </div>
  )
}
