import React from 'react'
import { Link } from 'react-scroll';
import CreateBlankProject from './create-blank-project';
import CreateNewProject from './create-new-project';
import CustomizeProjectAttributes from './customize-project-attributes';
import CustomizeTaskAttributes from "./customize-task-attributes"
import InviteTeamMembers from './invite-team-members';
import CreateProjectWorkflow from './create-project-workflow';
import TaskManagement from './task-management';
import Priority from './priority';
import Status from './status';
import QuickTaskFeature from './quick-task-feature';
import SortBy from './sort-by';
import Filterby from './filter-by';
import StageView from './stage-view';
import PeopleView from './people-view';
import PriorityView from './priority-view';
import InfoTab from './info-tab';
import SettingsTab from './settings-tab';
import DateView from './date-view';
import SpacesFolderManagement from './spaces-folder-management';
import CreateProjectSpace from './create-project-space';
import EllipsisIcon from './ellipsis-icon';
import CreateFoldersWithinSpace from './create-folders-within-space';
import KanbanView from './kanban-view';
import BoxView from './box-view';
import TableView from './table-view';


export default function Desktop() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Spaces & Folder Management', id: 'spaces-folder-management' },
        { title: 'Create Project Space', id: 'create-project-space' },
        { title: 'Create Folders within Space', id: 'create-folders-within-space' },
        { title: 'Ellipsis Icon (3-dot menu)', id: 'ellipsis-icon' },
        { title: 'Create a Blank Project', id: 'create-blank-project' },
        { title: 'Create a New Project', id: 'create-new-project' },
        { title: 'Customize Project Attributes', id: 'customize-project-attributes' },
        { title: 'Customize Task Attributes within the Project', id: 'customize-task-attributes-within-the-project' },
        { title: 'Invite Team Members', id: 'invite-team-members' },
        { title: 'Create a Project Workflow', id: 'create-project-workflow' },
        { title: 'Task Management', id: 'task-management' },
        { title: 'Priority', id: 'priority' },
        { title: 'Status', id: 'status' },
        { title: 'Quick Task Feature', id: 'quick-task-feature' },
        { title: 'Sort by', id: 'sort-by' },
        { title: 'Filter by', id: 'filter-by' },
        { title: 'Stage View', id: 'stage-view' },
        { title: 'People View', id: 'people-view' },
        { title: 'Date View', id: 'date-view' },
        { title: 'Priority View', id: 'priority-view' },
        { title: 'Kanban View', id: 'kanban-view' },
        { title: 'Box View', id: 'box-view' },
        { title: 'Table View', id: 'table-view' },
        { title: 'Info Tab', id: 'info-tab' },
        { title: 'Settings Tab', id: 'settings-tab' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                offset={-100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>A project in Workfast is a space where teams organize their work. You can create folders to store tasks, documents, and discussions related to that project. This helps you keep everything structured and easy to find. Projects allow you to assign tasks, set deadlines, and track progress, ensuring that all team members stay aligned and focused on the project's goals.</p>
                </div>
                 <div id='spaces-folder-management'><SpacesFolderManagement/></div>
                <div id='create-project-space'><CreateProjectSpace/></div>
                <div id='create-folders-within-space'><CreateFoldersWithinSpace/></div>
                <div id='ellipsis-icon'><EllipsisIcon/></div>
                <div id='create-blank-project'><CreateBlankProject /></div>
                <div id='create-new-project'><CreateNewProject/></div>
                <div id='customize-project-attributes'><CustomizeProjectAttributes/></div>
                <div id='customize-task-attributes-within-the-project'><CustomizeTaskAttributes/></div>
                <div id='invite-team-members'><InviteTeamMembers/></div>
                <div id='create-project-workflow'><CreateProjectWorkflow/></div>
                <div id='task-management'><TaskManagement /></div>
                <div id='priority'><Priority/></div>
                <div id='status'><Status/></div>
                <div id='quick-task-feature'><QuickTaskFeature/></div>
                <div id='sort-by'><SortBy/></div>
                <div id='filter-by'><Filterby/></div>
                <div id='stage-view'><StageView /></div>
                <div id='people-view'><PeopleView/></div>
                <div id='date-view'><DateView/></div>
                <div id='priority-view'><PriorityView/></div>
                <div id='kanban-view'><KanbanView/></div>
                <div id='box-view'><BoxView/></div>
                <div id='table-view'><TableView/></div>
                <div id='info-tab'><InfoTab/></div>
                <div id='settings-tab'><SettingsTab/></div>
            </div>
        </div>
    )
}
