import React from 'react'
import { ContractSvg, GrowthSvg, PadloackSvg, PaymentSvg, PresentationSvg, ProfitSvg } from '../svg'

export default function WhyOwnPincode() {

    return (
        <div className='px-[20px] sm:px-0 w-full sm:w-10/12 mt-[30px] sm:mt-[60px] relative mx-auto flex flex-col items-center'>
            <h4 className='text-[24px] sm:text-[36px] lg:text-[46px] text-[#2A2924] font-bold text-center'>Why Own a WorkFast.ai SaaS <br className='hidden lg:block'/> Franchise?</h4>
            <div className='mt-[20px] sm:mt-[42px] sm:w-11/12 xl:w-9/12 mx-auto grid sm:grid-cols-2 xl:grid-cols-3 gap-[20px] sm:gap-[64px]'>
                <div className='w-[280px] h-[200px] rounded-[10px] px-[24px] pt-[22px] mx-auto' style={{boxShadow : "0px 1px 4px 0px #00000040"}}>
                    <div>
                        <PadloackSvg />
                    </div>
                    <p className='text-[#252432] text-[20px] font-semibold mt-[12px]'>Right to Sell in Your <br /> Location</p>
                    <p className='text-[#8987A1] text-[14px] font-normal mt-[12px]'>Secure the Right to Sell</p>

                </div>
                <div className='w-[280px] h-[200px] rounded-[10px] px-[24px] pt-[22px] mx-auto' style={{boxShadow : "0px 1px 4px 0px #00000040"}}>
                    <div>
                        <PaymentSvg />
                    </div>
                    <p className='text-[#252432] text-[20px] font-semibold mt-[12px]'>Earn Recurring Monthly <br /> Revenue</p>
                    <p className='text-[#8987A1] text-[14px] font-normal mt-[12px]'>New sales & renewals generate <br /> recurring income.</p>

                </div>
                <div className='w-[280px] h-[200px] rounded-[10px] px-[24px] pt-[22px] mx-auto' style={{boxShadow : "0px 1px 4px 0px #00000040"}}>
                    <div>
                        <ContractSvg />
                    </div>
                    <p className='text-[#252432] text-[20px] font-semibold mt-[12px]'>Special Pricing on <br /> Licenses</p>
                    <p className='text-[#8987A1] text-[14px] font-normal mt-[12px]'>Get exclusive discounted rates.</p>

                </div>
                <div className='w-[280px] h-[200px] rounded-[10px] px-[24px] pt-[22px] mx-auto' style={{boxShadow : "0px 1px 4px 0px #00000040"}}>
                    <div>
                        <ProfitSvg />
                    </div>
                    <p className='text-[#252432] text-[20px] font-semibold mt-[12px]'>Metro & Tiered Pricing</p>
                    <p className='text-[#8987A1] text-[14px] font-normal mt-[12px]'>Tailored models based on <br /> business potential.</p>

                </div>
                <div className='w-[280px] h-[200px] rounded-[10px] px-[24px] pt-[22px] mx-auto' style={{boxShadow : "0px 1px 4px 0px #00000040"}}>
                    <div>
                        <PresentationSvg />
                    </div>
                    <p className='text-[#252432] text-[20px] font-semibold mt-[12px]'>Training & Assistance</p>
                    <p className='text-[#8987A1] text-[14px] font-normal mt-[12px]'>Access technical training, sales <br/> strategies & certification.</p>

                </div>
                <div className='w-[280px] h-[200px] rounded-[10px] px-[24px] pt-[22px] mx-auto' style={{boxShadow : "0px 1px 4px 0px #00000040"}}>
                    <div>
                        <GrowthSvg />
                    </div>
                    <p className='text-[#252432] text-[20px] font-semibold mt-[12px]'>Growth & Expansion</p>
                    <p className='text-[#8987A1] text-[14px] font-normal mt-[12px]'>Start with SaaS, scale as <br/> you grow.</p>

                </div>
            </div>
            <h5 className='text-[#000000] text-[16px] sm:text-[28px] lg:text-[32px] font-semibold text-center mt-[20px] sm:mt-[46px]'>The demand for AI-driven business tools is rising. <br className='hidden lg:block'/> Position yourself for success!</h5>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[13px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[28px]' style={{boxShadow: "5px 5px 100px 0px #FFDD0933"}}>Check Pincode Availability & Apply Now</button> */}
        </div>
    )
}
