import React from 'react'
// import { TbArrowNarrowRight } from "react-icons/tb";
import { AspiringSvg, EmployeeSvg, EnterpreneurSvg, ItConsultantSvg, SalesSvg, SoftwareSvg } from '../svg';

export default function WhoOwnPincode() {
    const [position, setPosition] = React.useState(window.innerWidth <= 645);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);
    return (
        <div className='mt-[30px] sm:mt-[60px] relative'>
            <div className='bg-[#F0FBF8] w-full px-[20px] rounded-[30px] sm:rounded-[60px] py-[25px] sm:py-[50px]'>
                <h4 className='text-[#0F0200] font-bold text-[21px] sm:text-[40px] xl:text-[50px] text-center'>Who Should Own a WorkFast.ai <br className='hidden lg:block' /> SaaS Franchise?</h4>
                <h5 className='mt-[12px] sm:mt-[24px] font-normal text-[#000000] text-[14px] sm:text-[18px] xl:text-[24px] text-center'>Anyone with passion, courage, and boldness to create an impact can own a WorkFast.ai SaaS <br className='hidden lg:block' /> Franchise. If you have the drive to succeed and transform businesses, this is your opportunity!</h5>
                <div className='mt-[20px] sm:mt-[46px] px-[20px] sm:px-0 w-full sm:w-10/12 mx-auto grid xl:grid-cols-2 gap-[20px] sm:gap-[40px] lg:gap-[77px] justify-center items-center'>
                    <div>
                        <div className='flex gap-[20px] sm:gap-[32px] text-start sm:items-center'>
                            <div className='size-[44px] sm:size-[84px] shrink-0 flex justify-center items-center rounded-[14px] sm:rounded-[21px]' style={{ boxShadow: "0px 0px 4px 0px #00C7BE99" }}>
                                <AspiringSvg size={position ? "20" : "46"} />
                            </div>
                            <div>
                                <p className='text-[#292D34] font-bold text-[16px] sm:text-[22px]'>Aspiring Entrepreneurs</p>
                                <p className='text-[#292D34] font-normal text-[12px] sm:text-[18px]'>Individuals looking to start their first business with minimal risk.</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] sm:gap-[32px] text-start sm:items-center mt-[20px] sm:mt-[42px]'>
                            <div className='size-[44px] sm:size-[84px] shrink-0 flex justify-center items-center rounded-[14px] sm:rounded-[21px]' style={{ boxShadow: "0px 0px 4px 0px #FF950099" }}>
                                <EnterpreneurSvg size={position ? "20" : "46"} />
                            </div>
                            <div>
                                <p className='text-[#292D34] font-bold text-[16px] sm:text-[22px]'>Entrepreneurs & Business Owners</p>
                                <p className='text-[#292D34] font-normal text-[12px] sm:text-[18px]'>Looking for a high-growth AI SaaS opportunity.</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] sm:gap-[32px] text-start sm:items-center mt-[20px] sm:mt-[42px]'>
                            <div className='size-[44px] sm:size-[84px] shrink-0 flex justify-center items-center rounded-[14px] sm:rounded-[21px]' style={{ boxShadow: "0px 0px 4px 0px #34C75999" }}>
                                <ItConsultantSvg size={position ? "26" : "46"} />
                            </div>
                            <div>
                                <p className='text-[#292D34] font-bold text-[16px] sm:text-[22px]'>IT Consultants & Professionals</p>
                                <p className='text-[#292D34] font-normal text-[12px] sm:text-[18px]'>Wanting to implement and sell AI <br className='hidden xl:block' /> solutions.</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] sm:gap-[32px] text-start sm:items-center  mt-[20px] sm:mt-[42px]'>
                            <div className='size-[44px] sm:size-[84px] shrink-0 flex justify-center items-center rounded-[14px] sm:rounded-[21px]' style={{ boxShadow: "0px 0px 4px 0px #218BFF99" }}>
                                <SoftwareSvg size={position ? "26" : "46"} />
                            </div>
                            <div>
                                <p className='text-[#292D34] font-bold text-[16px] sm:text-[22px]'>Software Distributors</p>
                                <p className='text-[#292D34] font-normal text-[12px] sm:text-[18px]'>Seeking to add a high-demand SaaS product <br className='hidden xl:block' /> to their portfolio.</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] sm:gap-[32px] text-start sm:items-center  mt-[20px] sm:mt-[42px]'>
                            <div className='size-[44px] sm:size-[84px] shrink-0 flex justify-center items-center rounded-[14px] sm:rounded-[21px]' style={{ boxShadow: "0px 0px 4px 0px #AF52DE99" }}>
                                <SalesSvg size={position ? "26" : "46"} />
                            </div>
                            <div>
                                <p className='text-[#292D34] font-bold text-[16px] sm:text-[22px]'>Sales & Marketing Experts</p>
                                <p className='text-[#292D34] font-normal text-[12px] sm:text-[18px]'>Ready to leverage their network and generate <br className='hidden xl:block' /> recurring revenue.</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] sm:gap-[32px] text-start sm:items-center mt-[20px] sm:mt-[42px]'>
                            <div className='size-[44px] sm:size-[84px] shrink-0 flex justify-center items-center rounded-[14px] sm:rounded-[21px]' style={{ boxShadow: "0px 0px 4px 0px #FF3B3099" }}>
                                <EmployeeSvg size={position ? "20" : "46"} />
                            </div>
                            <div>
                                <p className='text-[#292D34] font-bold text-[16px] sm:text-[22px]'>Employees who want to have a secondary income</p>
                                <p className='text-[#292D34] font-normal text-[12px] sm:text-[18px]'>Professionals seeking an additional revenue stream without quitting their jobs.</p>
                            </div>
                        </div>
                    </div>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/franchises/who-own-pincode.webp" alt='banner' loading='lazy' className='w-full' />
                </div>
                <p className='xl:mt-[46px] text-[#000000] text-[18px] sm:text-[24px] font-medium text-center px-[20px] sm:px-0'>Build a primary income source or a sustainable secondary revenue stream. <br className='hidden lg:block' /> This is your chance!</p>
            </div>
            {/* <div className='bg-[#E4F8F2] w-full rounded-b-[30px] sm:rounded-b-[60px] py-[20px] flex justify-center items-center gap-[15px] sm:gap-[23px] cursor-pointer text-[14px] sm:text-[20px] font-semibold text-[#292D34]'>
                <h6>Apply Now & Secure Your Pincode</h6>
                <TbArrowNarrowRight className='text-[#00B884]' />
            </div> */}
        </div>

    )
}
