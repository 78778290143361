import React from 'react'

export default function Mobile({isMobileAtTop}) {
    return (
        <div className='mt-[20px] sm:mt-[120px] lg:mt-[180px] bg-[#FDF7FF] pt-[20px] sm:pt-[40px] xl:pt-[55px] pb-[20px]' id='mobile'>
            <div className='px-[20px] sm:px-0 w-full sm:w-10/12 mx-auto relative'>
                <div className='flex flex-col gap-[20px] sm:gap-[60px] xl:gap-[90px] justify-center items-center w-full text-center'>
                    <div>
                        <h3 className='text-[#000000] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[47px] font-bold'>Mobile First App</h3>
                        <h4 className='text-[#454545] text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] font-normal mx-auto'>Workfast.ai offers the same intuitive UI/UX on mobile,enabling users to <br/> effortlessly manage teams anytime, anywhere.</h4>
                    </div>
                    <div className='relative flex justify-center items-center'>
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/mobile-3.webp" alt='mobile-1' loading='lazy' className={`absolute z-[1] w-[40%] sm:w-[80%] bottom-0  transition-all duration-700 ${isMobileAtTop ? "-translate-x-[100px] sm:-translate-x-[240px] xl:-translate-x-[300px]" : ""}`} />
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/mobile-4.webp" alt='mobile-1' loading='lazy' className={`absolute z-[3] w-[40%] sm:w-[80%] bottom-0 transition-all duration-700 ${isMobileAtTop ? "-translate-x-[50px] sm:-translate-x-[120px] xl:-translate-x-[180px]" : ""}`} />
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/mobile-5.webp" alt='mobile-1' loading='lazy' className='relative z-[5] w-[40%] sm:w-[80%]' />
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/mobile-2.webp" alt='mobile-1' loading='lazy' className={`absolute z-[3] w-[40%] sm:w-[80%] bottom-0 transition-all duration-700 ${isMobileAtTop ? "translate-x-[50px] sm:translate-x-[120px] xl:translate-x-[180px]" : ""}`} />
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/mobile-1.webp" alt='mobile-1' loading='lazy' className={`absolute z-[1] w-[40%] sm:w-[80%]  bottom-0 transition-all duration-700 ${isMobileAtTop ? "translate-x-[100px] sm:translate-x-[240px] xl:translate-x-[300px]" : ""}`} />
                    </div>
                </div>
            </div>
        </div>
    )
}
