import React from 'react'
import { NumberBgSvg } from '../../../confidential/component/svg';
import bell from "../../../../images/confidential/bell.png"

export default function Traning() {
    const [position, setPosition] = React.useState(window.innerWidth);

    const points = [
        { number: "01", colour: "#32ADE6", describe: "Comprehensive Onboarding Training – Sales, Marketing & Implementation Training" },
        { number: "02", colour: "#007AFF", describe: "Marketing Materials & Sales Collateral – Customizable templates, brochures, presentations. " },
        { number: "03", colour: "#5856D6", describe: "Dedicated Support Team – Technical & Sales Assistance 24/7." },
        { number: "04", colour: "#AF52DE", describe: "Regular Webinars & Strategy Calls – Continous business growth support." },
    ]

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='mt-[20px] sm:mt-[60px] bg-[#EDFAFE] w-full py-[40px] flex flex-col items-center relative px-[20px]'>
            <img src={bell} alt='bell' className='absolute right-[66px] hidden lg:block'/>
            <h4 className='text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[52px] text-[#181F38] font-bold text-center'>Training & Continous Support</h4>
            <h5 className='text-[#000000] text-[16px] sm:text-[20px] lg:text-[24px] xl:text-[34px] font-medium mt-[20px] sm:mt-[32px] text-center'>Get the Training & Support You Need to Succeed!</h5>
            {/* <button className='py-[15px] px-[36px] rounded-full bg-[#FFDD09] text-[#2A2200] text-[12px] sm:text-[16px] font-semibold mt-[20px] sm:mt-[36px]' style={{ boxShadow: "5px 5px 100px 0px #FFDD0933" }}>Join the Franchise & Get Certified</button> */}
            <div className='mt-[20px] sm:mt-[40px] flex flex-col gap-[20px] sm:gap-[32px] items-start sm:w-9/12 mx-auto'>
                {points?.map((i, index) => (
                    <div key={index} className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start lg:items-center'>
                        <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                            <div className='absolute left-0 z-[0]'>
                                <NumberBgSvg colour={i?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"} />
                            </div>
                            <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{i.number}</div>
                        </div>
                        <div>
                            <p className='text-[#181F38] font-semibold text-[18px] sm:text-[26px]'>{i?.describe}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
